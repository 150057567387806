import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 200,
  },
}))

export default function AgreementDropDownList({
  providerContracts,
  label,
  helperText,
  error,
  fullWidth,
  variant = 'standard',
  margin = 'normal',
  className,
  name,
  size = 'medium',
  disabled,
  ...props
}) {
  const classes = useStyles()

  return (
    <FormControl
      size={size}
      className={[classes.select, className].join(' ')}
      fullWidth={fullWidth}
      variant={variant}
      margin={margin}
      error={error}
    >
      <InputLabel id="provider-agreement-label">{label}</InputLabel>
      <Select
        labelId="provider-agreement-label"
        name={name}
        label={label}
        disabled={disabled}
        {...(disabled && { IconComponent: () => null })}
        {...props}
      >
        {providerContracts.map(({ ContractNr }, index) => (
          <MenuItem key={index} value={ContractNr}>
            {ContractNr}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
