import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { useMutation } from "react-query";
import { setPassword } from "../../api/API";
import { useSnackBar } from "../providers/SnackBarProvider";
import { useLocation, useHistory } from "react-router-dom";
import { REGEX_PASSWORD, URL_PARAMS } from "../../constants/Constants";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  dialogTitle: {
    paddingTop: 4,
  },
});

export default function ModalProfile({ open, onClose }) {
  const { showSnackBar } = useSnackBar();
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    userName: localStorage.getItem("userName"),
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    password: "",
    passwordConfirmation: "",
  });
  const history = useHistory();
  const location = useLocation();
  const mustChangePassword = Boolean(
    new URLSearchParams(location.search).get(URL_PARAMS.mustChangePassword)
  );
  const passwordChangeMessage = new URLSearchParams(location.search).get(
    URL_PARAMS.passwordChangeMessage
  );
  const [formErrors, setFormErrors] = React.useState({});
  const [setPasswordMutation] = useMutation((data) => setPassword(data), {
    onSuccess: () => {
      showSnackBar();
      onClose();
      if (mustChangePassword) {
        localStorage.clear();
        history.replace("/login");
      }
    },
    onError: (error) =>
      showSnackBar({
        severity: 'error',
        text: `kļūda: ${error.response?.data.error}`,
      }),
  });
  const onFieldChange = ({ target }) =>
    setFormState({ ...formState, [target.name]: target.value });

  const changePassword = () => {
    const { password } = formState;
    setFormErrors({});
    if (!formState.password.match(REGEX_PASSWORD))
      return setFormErrors({
        password:
          "Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam",
      });
    if (formState.password !== formState.passwordConfirmation)
      return setFormErrors({
        passwordConfirmation: "Parole neatbilst",
      });
    setPasswordMutation({
      UserId: Number(localStorage.getItem("userId")),
      UserPass: password,
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open || mustChangePassword}
      onClose={onClose}
    >
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              {passwordChangeMessage ? passwordChangeMessage : "Mans profils"}
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              disabled
              variant="outlined"
              margin="dense"
              label="Vārds"
              fullWidth
              value={formState.firstName}
            />

            <TextField
              disabled
              variant="outlined"
              margin="dense"
              label="Uzvārds"
              fullWidth
              value={formState.lastName}
            />

            <TextField
              disabled
              variant="outlined"
              margin="dense"
              label="Lietotāja vārds"
              fullWidth
              name="userName"
              autoComplete="off"
              value={formState.userName}
              onChange={onFieldChange}
              helperText={formErrors.userName}
              error={Boolean(formErrors.userName)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Parole"
              fullWidth
              type="password"
              name="password"
              autoComplete="off"
              value={formState.password}
              onChange={onFieldChange}
              helperText={formErrors.password}
              error={Boolean(formErrors.password)}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam">
                    <InfoIcon color="primary" />
                  </Tooltip>
                ),
              }}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Parole vēlreiz"
              fullWidth
              type="password"
              name="passwordConfirmation"
              autoComplete="off"
              value={formState.passwordConfirmation}
              onChange={onFieldChange}
              helperText={formErrors.passwordConfirmation}
              error={Boolean(formErrors.passwordConfirmation)}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam">
                    <InfoIcon color="primary" />
                  </Tooltip>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box mx={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={changePassword}
                color="primary"
              >
                nomainīt paroli
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
