import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import moment from 'moment'
import { useMutation } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import PeriodsTagList from '../../elements/PeriodsTagList'
import DiscountTypesDropDownList from '../../elements/DiscountTypesDropDownList'
import { setDiscountRecalc } from '../../../api/API'
import { defaultErrMsg, wrongValue, remove, add } from '../../../constants/Constants'
import ActionTypeDropDownList from '../../elements/ActionTypesDropDownList'
import { eurosToCents } from '../../../utils/Number'
import { confirmDialog } from '../../elements/ConfirmDialog'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 4,
    textTransform: 'uppercase',
  },
  button: {
    minWidth: 130,
  },
  contentBox: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
  },
})

export default function ModalTopUp({ open, onClose, onSuccess, person }) {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon className={classes.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="center">
            Labot konta bilanci
          </Box>
        </DialogTitle>
        <Form onSuccess={onSuccess} onClose={onClose} person={person} />
      </Box>
    </Dialog>
  )
}

function Form({ onSuccess, onClose, person }) {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [formState, setFormState] = React.useState({
    selectedPerson: person,
    category: [],
    statusEffectList: [],
    comment: '',
    action: add.value,
    frozenSum: '',
    warningDlg: "warning_dlg",
  })
  const [formErrors, setFormErrors] = React.useState({})

  const [editDiscountMutation] = useMutation(
    ({ selectedPerson, category, statusEffectList, comment, action, frozenSum, warningDlg }) => {
      const descendingStatusEffectList = statusEffectList
        .slice()
        .sort(
          (a, b) =>
            moment(b.StatusMonth, 'DD.MM.YYYY').valueOf() -
            moment(a.StatusMonth, 'DD.MM.YYYY').valueOf()
        )

      return setDiscountRecalc({
        PersonCode: selectedPerson.PersonCode,
        StatusCodeList: category.map((category) => ({
          ProtectedUserStatusCode: category.DiscountTypeId,
        })),
        StatusEffectList: descendingStatusEffectList,
        AddReason: comment,
        Action: action,
        FrozenSum: action === remove.value && frozenSum !== '' ? eurosToCents(parseFloat(frozenSum.replace(',', '.'))) : null,
        WarningDlg: warningDlg,
      })
    },
    {
      onSuccess: (data) => {
        const warning = data?.data?.Warning || undefined

        showSnackBar({
          severity: warning && 'warning',
          text: warning,
        })
        onSuccess()
        onClose()
      },
      onError: (error) => {
        if (error.response?.data.warningDlg) {
          callDialog(error.response?.data.error, error.response?.data.warningDlg)
        } else {
          showSnackBar({
            severity: 'error',
            text: `kļūda: ${error.response?.data.error}`,
          })
        }
      },
    }
  )

  const callDialog = async (error, warningDlg) => {
    const confirm = await confirmDialog({
      text: 'Nesaglabātas izmaiņas!',
      description: `${error}`,
    });  
    if (confirm.ok) {
      setFormState((prevState) => {
        const updatedState = { ...prevState, warningDlg: warningDlg }
        editDiscountMutation(updatedState)        
        return updatedState
      })
    }
  }

  const onFieldChange = ({ target }) => {
    setFormState({ ...formState, [target.name]: target.value })
  }

  const onFrozenSumChange = ({ target }) => {
    let inputValue = target.value.replace(/[^\d,]/g, '')

    const commaIndex = inputValue.indexOf(',')
    if (commaIndex !== -1) {
      const beforeComma = inputValue.slice(0, commaIndex + 1)
      const afterComma = inputValue
        .slice(commaIndex + 1)
        .replace(/,/g, '')
        .slice(0, 2)

      inputValue = beforeComma + afterComma
    }

    setFormState({ ...formState, [target.name]: inputValue })
  }

  const onSelect = (event) => setFormState({...formState, [event.target.name]: event.target.value })  

  const onFieldChangeAutoComplete = (field, value) => setFormState({ ...formState, [field]: value })

  const onPeriodsTagChange = (dateList) => {
    const statusEffectList = dateList.map((date) => ({ StatusMonth: date }))

    setFormState({
      ...formState,
      statusEffectList,
    })
  }

  const isFormValid = () => {
    const { selectedPerson, category, statusEffectList, comment, action, frozenSum } = formState
    const _errors = []
    if (!selectedPerson) _errors['selectedPerson'] = defaultErrMsg
    if (!category || !category.length > 0) _errors['category'] = defaultErrMsg
    if (!statusEffectList.length > 0) _errors['statusEffectList'] = defaultErrMsg
    if (!comment) _errors['comment'] = defaultErrMsg

    if (frozenSum !== '' && action === remove.value) {
      let numericValue = parseFloat(frozenSum.replace(',', '.'))    
      if (isNaN(numericValue) || numericValue < 0.01 || numericValue > 99999.99) 
        _errors['frozenSum'] = wrongValue      
    }

    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    editDiscountMutation(formState)
  }

  return (
    <>
      <DialogContent className={classes.contentBox}>
        <Box pb={1}>
          <TextField
            variant="outlined"
            margin="dense"
            label="Personas kods"
            name="personCode"
            autoComplete="off"
            fullWidth
            value={formState.selectedPerson?.PersonCode || ''}
            helperText={formErrors.selectedPerson}
            error={Boolean(formErrors.selectedPerson)}
            disabled
          />

          <TextField
            variant="outlined"
            margin="dense"
            label="Vārds"
            fullWidth
            value={[
              formState.selectedPerson?.PersonFirstName,
              formState.selectedPerson?.PersonOtherNames,
            ].join(' ')}
            helperText={formErrors.selectedPerson}
            error={Boolean(formErrors.selectedPerson)}
            disabled
          />

          <TextField
            variant="outlined"
            margin="dense"
            label="Uzvārds"
            fullWidth
            value={formState.selectedPerson?.PersonLastName || ''}
            helperText={formErrors.selectedPerson}
            error={Boolean(formErrors.selectedPerson)}
            disabled
          />

          <DiscountTypesDropDownList
            fullWidth
            label="Kategorija"
            margin="dense"
            multiSelect
            helperText={formErrors.category}
            error={Boolean(formErrors.category)}
            value={formState.categoryMultiple}
            inputValue={formState.categoryInput}
            onChange={(e, newValue) => onFieldChangeAutoComplete('category', newValue)}
            onInputChange={(e, newInputValue) =>
              onFieldChangeAutoComplete('categoryInput', newInputValue)
            }
          />

          <ActionTypeDropDownList
            selectedValue={formState.action}
            name="action"
            onChange={onSelect}
            error={Boolean(formErrors.frozenSum)}
            inputName="frozenSum"
            helperText={formErrors.frozenSum}
            inputValue={formState.frozenSum}
            onInput={onFrozenSumChange}
          />

          <PeriodsTagList
            fullWidth
            onChange={onPeriodsTagChange}
            helperText={formErrors.statusEffectList}
            error={Boolean(formErrors.statusEffectList)}
          />

          <TextField
            variant="outlined"
            multiline
            rows={2}
            margin="dense"
            label="Iemesls"
            name="comment"
            autoComplete="off"
            fullWidth
            onChange={onFieldChange}
            value={formState.comment}
            helperText={formErrors.comment}
            FormHelperTextProps={{
              style: { marginLeft: '8px' },
            }}
            error={Boolean(formErrors.comment)}
          />
        </Box>
        <Box pt={1} display="flex" justifyContent="flex-end">
          <Button className={classes.button} variant="contained" onClick={onSubmit} color="primary">
            SAGLABĀT
          </Button>
        </Box>
      </DialogContent>
    </>
  )
}
