import React from 'react'
import { useQuery } from 'react-query'
import { fetchIntegrationData } from '../../../api/API'
import moment from 'moment'
import { addDashToPersonCode, normalizePersonCode } from '../../../utils/Misc'
import { PERSON_CODE_LENGTH } from '../../../constants/Constants'
import CustomizedMaterialTable from '../CustomizedMaterialTable'

export default function SifPersonsIntegration({ selectedPeriod, personCode }) {
  const [rows, setRows] = React.useState([])
  const columnsSIF = [
    { title: 'Ģimenes ID', field: 'FamilyId', defaultGroupOrder: 0 },
    {
      title: 'Personas kods',
      field: 'PersonCode',
      render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
    },
    {
      title: 'Vārds',
      field: 'PersonFirstName',
      render: ({ PersonFirstName, PersonOtherNames }) =>
        [PersonFirstName, PersonOtherNames].join(' '),
    },
    { title: 'Uzvārds', field: 'PersonLastName' },
    {
      title: 'Pazīme',
      field: 'MainPerson',
      render: ({ MainPerson }) => (MainPerson === 0 ? 'Bērns' : 'Vecāks'),
    },
  ]

  const { isLoading } = useQuery(
    [
      'family-data',
      {
        personCode,
        selectedPeriod,
      },
    ],
    (_, { personCode, selectedPeriod }) => {
      const normalizedPersonCode = normalizePersonCode(personCode)
      if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return
      return fetchIntegrationData({
        PersonCode: normalizedPersonCode,
        SifStatusFrom: moment(selectedPeriod).format('DD.MM.YYYY'),
        SifStatusTill: moment(selectedPeriod).endOf('month').format('DD.MM.YYYY'),
      })
    },
    {
      onSuccess: (data) => {
        if (data) setRows(data)
        else setRows([])
      },
      onError: (error) => {
        console.error(error)
        setRows([])
      },
    }
  )

  return (
    <CustomizedMaterialTable
      columns={columnsSIF}
      data={rows}
      isLoading={isLoading}
      options={{
        defaultExpanded: true,
      }}
    />
  )
}
