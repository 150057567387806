import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import CustomizedMaterialTable from '../elements/CustomizedMaterialTable'
import { useMutation, useQuery } from 'react-query'
import { fetchDeletedPersonsData, restorePerson } from '../../api/API'
import moment from 'moment'
import { useSnackBar } from '../providers/SnackBarProvider'
import { confirmDialog } from '../elements/ConfirmDialog'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import ResetButton from '../elements/ResetButton'
import { addDashToPersonCode, normalizePersonCode } from '../../utils/Misc'
import { PERSON_CODE_LENGTH } from '../../constants/Constants'

const columns = [
  {
    field: 'PersonCode',
    title: 'Personas kods',
    render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
  },
  {
    field: 'PersonFirstName',
    title: 'Vārds',
    render: ({ PersonFirstName, PersonOtherNames }) =>
      [PersonFirstName, PersonOtherNames].join(' '),
  },
  {
    field: 'PersonLastName',
    title: 'Uzvārds',
  },
  {
    field: 'Status',
    title: 'Statuss',
  },
  {
    field: 'DeleteDate',
    title: 'Dzēšanas datums, laiks',
    customSort: (a, b) =>
      moment(a.DeleteDate, 'DD.MM.YYYY HH:mm:s').valueOf() -
      moment(b.DeleteDate, 'DD.MM.YYYY HH:mm:s').valueOf(),
  },
  { field: 'DeleteReason', title: 'Dzēšanas iemesls' },
  { field: 'RestoreReason', title: 'Atjaunošanas iemesls' },
]

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        width: '100%',
      },
    },
  },
  search: {
    width: 150,
  },
}))

export default function DeletedProtectedPersons() {
  const classes = useStyles()
  const [personCode, setPersonCode] = React.useState('')
  const [rows, setRows] = React.useState([])
  const { showSnackBar } = useSnackBar()

  const { isLoading, refetch } = useQuery(
    ['deleted-protected-persons', { personCode }],
    () => {
      const normalizedPersonCode = normalizePersonCode(personCode)
      if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return
      return fetchDeletedPersonsData({
        PersonCode: normalizedPersonCode,
      })
    },
    {
      onSuccess: (data) => {
        if (data) setRows(data)
        else setRows([])
      },
      onError: (error) => {
        console.error(error)
        setRows([])
      },
    }
  )
  const [restorePersonMutation] = useMutation(
    ({ PersonCode, reason }) => restorePerson({ PersonCode, Reason: reason }),
    {
      onSuccess: () => {
        refetch()
        showSnackBar()
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        }),
    }
  )

  const onRestorePerson = async (_, { PersonCode, PersonFirstName, PersonLastName }) => {
    const field = { label: 'Iemesls', id: 'reason' }
    const confirm = await confirmDialog({
      text: 'Vai tiešām vēlaties atjaunot lietotāju?',
      description: `Lietotājs: ${PersonFirstName} ${PersonLastName}`,
      fields: [field],
    })

    if (confirm.ok) {
      const reason = confirm.fields.find((f) => f.id === field.id).value
      restorePersonMutation({ PersonCode, reason })
    }
  }

  const resetFilters = () => {
    setPersonCode('')
  }
  return (
    <>
      <Box className={classes.searchWrapper} flexWrap="wrap" display="flex" alignItems="center">
        <TextField
          autoFocus
          variant="standard"
          label="Personas kods"
          className={classes.search}
          value={personCode}
          onChange={({ target: { value } }) => setPersonCode(value)}
          InputProps={{
            endAdornment: (
              <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />
            ),
          }}
        />
        <ResetButton onClick={resetFilters} />
      </Box>
      <CustomizedMaterialTable
        columns={columns}
        data={rows}
        isLoading={isLoading}
        actions={[
          (rowData) => ({
            icon: () => <RestoreFromTrashIcon />,
            tooltip: 'Atjaunot lietotāju',
            onClick: onRestorePerson,
            disabled: rowData.Status !== 'Dzēsts',
          }),
        ]}
      />
    </>
  )
}
