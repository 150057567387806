import React from "react";
import { useQuery } from "react-query";
import {
    fetchFamilyData,
} from "../../../api/API";
import moment from "moment";
import { addDashToPersonCode, normalizePersonCode } from "../../../utils/Misc";
import { PERSON_CODE_LENGTH } from "../../../constants/Constants";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";

export default function FamilyMembers({ selectedPeriod, personCode }) {
    const [rows, setRows] = React.useState([]);
    const columnsPMLP = [
        { title: "Ģimenes ID", field: "FamilyId", defaultGroupOrder: 0 },
        {
            title: "Personas kods",
            field: "PersonCode",
            render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
        },
        {
            title: "Vārds",
            field: "PersonFirstName",
            render: ({ PersonFirstName, PersonOtherNames }) =>
                [PersonFirstName, PersonOtherNames].join(" "),
        },
        { title: "Uzvārds", field: "PersonLastName" },
        {
            title: "Datums no",
            field: "FamilyStatusFrom",
            customSort: (a, b) =>
                moment(a.FamilyStatusFrom, "DD.MM.YYYY").valueOf() -
                moment(b.FamilyStatusFrom, "DD.MM.YYYY").valueOf(),
        },
        {
            title: "Datums līdz",
            field: "FamilyStatusTill",
            customSort: (a, b) =>
                moment(a.FamilyStatusTill, "DD.MM.YYYY").valueOf() -
                moment(b.FamilyStatusTill, "DD.MM.YYYY").valueOf(),
        },
        {
            title: "Pazīme",
            field: "MainPerson",
            render: ({ MainPerson }) => (MainPerson === 0 ? "Bērns" : "Vecāks"),
        },
    ];

    const { isLoading } = useQuery(
        [
            "family-data",
            {
                personCode,
                selectedPeriod,
            },
        ],
        (_, { personCode, selectedPeriod }) => {
            const normalizedPersonCode = normalizePersonCode(personCode);
            if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return;
            return fetchFamilyData({
                PersonCode: normalizedPersonCode,
                ProviderId: null,
                FamilyId: null,
                FamilyStatusFrom: moment(selectedPeriod).format("DD.MM.YYYY"),
                FamilyStatusTill: moment(selectedPeriod)
                    .endOf("month")
                    .format("DD.MM.YYYY"),
            });
        },
        {
            onSuccess: (data) => {
                if (data) setRows(data);
                else setRows([]);
            },
            onError: (error) => {
                console.error(error);
                setRows([]);
            },
        }
    );

    return (
        <CustomizedMaterialTable
            columns={columnsPMLP}
            data={rows}
            isLoading={isLoading}
            options={{
                defaultExpanded: true,
            }}
        />
    );
}