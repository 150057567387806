import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useMutation } from "react-query";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import CancelIcon from "@material-ui/icons/Cancel";
import DiscountTypesDropDownList from "../../elements/DiscountTypesDropDownList";
import DatePicker from "../../elements/DatePicker";
import { setPersonStatus } from "../../../api/API";
import { useSnackBar } from "../../providers/SnackBarProvider";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { normalizePersonCode } from "../../../utils/Misc";
import { REGEX_PERSON_CODE, defaultErrMsg } from "../../../constants/Constants";

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  dialogTitle: {
    paddingTop: 4,
  },
  button: {
    minWidth: 130,
  },
});

const insufficientRelatedPersons = "Jānorāda vismaz viena saistītā persona";

export default function ModalAddEditProtectedPerson({
  open,
  onSuccess,
  onClose,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              AIZSARGĀTAIS LIETOTĀJS
            </Box>
          </DialogTitle>
          <Form onClose={onClose} onSuccess={onSuccess} />
        </Box>
      </Box>
    </Dialog>
  );
}

function Form({ onClose, onSuccess }) {
  const classes = useStyles();
  const { showSnackBar } = useSnackBar();
  //form data
  const [formState, setFormState] = React.useState({
    personCode: "",
    firstName: "",
    lastName: "",
    category: [],
    period: null,
    relatedPersonList: [],
    comment: "",
  });
  //form errors
  const [formErrors, setFormErrors] = React.useState({});
  const [editPersonMutation] = useMutation(
    ({
      category,
      comment,
      firstName,
      lastName,
      period,
      personCode,
      relatedPersonList,
    }) =>
      setPersonStatus({
        PersonCode: normalizePersonCode(personCode),
        PersonFirstName: firstName,
        PersonLastName: lastName,
        StatusEffectTill: period ? moment(period).format("DD.MM.YYYY") : "",
        StatusCodeList: category.map((category) => ({
          ProtectedUserStatusCode: category.DiscountTypeId,
        })),
        RelatedPersonList:
          relatedPersonList.length > 0
            ? relatedPersonList.map((person) => {
              return {
                ProtectedUserStatusCode: person.category,
                PersonCode: normalizePersonCode(person.personCode),
              };
            })
            : undefined,
        AddReason: comment,
      }),
    {
      onSuccess: (data) => {
        const warning = data?.data?.Warning || undefined

        showSnackBar({
          severity: warning && 'warning',
          text: warning,
        });
        onClose();
        onSuccess();
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        });
      }
    }
  );

  const onFieldChange = ({ target }) =>
    setFormState({ ...formState, [target.name]: target.value });

  const onMultipleFieldChange = (field, value) =>
    setFormState({ ...formState, [field]: value });

  const onArrayFieldChange = (index, property, { target }) =>
    setFormState({
      ...formState,
      [target.name]: formState[target.name].map((el, ind) => {
        if (index === ind) {
          return {
            ...el,
            [property]: target.value,
          };
        } else {
          return el;
        }
      }),
    });

  const isFormValid = () => {
    const {
      category,
      firstName,
      lastName,
      personCode,
      period,
      relatedPersonList,
    } = formState;
    const _errors = [];
    if (!firstName) _errors["firstName"] = defaultErrMsg;
    if (!lastName) _errors["lastName"] = defaultErrMsg;
    if (!category || !category.length > 0) _errors["category"] = defaultErrMsg;
    if (!personCode.match(REGEX_PERSON_CODE))
      _errors["personCode"] =
        "Personas kods ir jānorādā formātos: 111111-11111 vai 11111111111";
    if (!personCode) _errors["personCode"] = defaultErrMsg;
    if (!period) _errors["period"] = defaultErrMsg;
    if (period?.diff(moment()) < 0)
      _errors["period"] =
        "Statusa beigu datums ir mazāks par šodienas datumu vai tas pārsniedz 1 gadu";
    if (relatedPersonList.some((person) => !person.personCode.match(REGEX_PERSON_CODE)))
      _errors["relatedPersonPersonCode"] =
        "Personas kods ir jānorādā formātos: 111111-11111 vai 11111111111";
    if (relatedPersonList.some((person) => !person.personCode)
    )
      _errors["relatedPersonPersonCode"] = defaultErrMsg;
    if (relatedPersonList.some((person) => !person.category)
    )
      _errors["relatedPersonCategory"] = defaultErrMsg;
    if (
      category.some(
        (category) =>
          category.DiscountTypeId === 4 || category.DiscountTypeId === 5
      ) &&
      relatedPersonList.length < 1
    )
      _errors["category"] = insufficientRelatedPersons;
    setFormErrors(_errors);

    return Object.keys(_errors).length === 0;
  };

  const onSubmit = () => {
    if (!isFormValid()) return;
    editPersonMutation(formState);
  };

  return (
    <>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="dense"
          label="Personas kods"
          fullWidth
          name="personCode"
          autoComplete="off"
          value={formState.personCode}
          onChange={onFieldChange}
          helperText={formErrors.personCode}
          error={Boolean(formErrors.personCode)}
          inputProps={{ maxLength: 12 }}
        />
        <TextField
          variant="outlined"
          margin="dense"
          label="Vārds"
          name="firstName"
          autoComplete="off"
          fullWidth
          onChange={onFieldChange}
          value={formState.firstName}
          helperText={formErrors.firstName}
          error={Boolean(formErrors.firstName)}
        />
        <TextField
          variant="outlined"
          margin="dense"
          name="lastName"
          label="Uzvārds"
          autoComplete="off"
          fullWidth
          onChange={onFieldChange}
          value={formState.lastName}
          helperText={formErrors.lastName}
          error={Boolean(formErrors.lastName)}
        />

        <DiscountTypesDropDownList
          fullWidth
          label="Kategorija"
          multiSelect
          helperText={formErrors.category}
          error={Boolean(formErrors.category)}
          value={formState.categoryMultiple}
          inputValue={formState.categoryInput}
          onChange={(e, newValue) =>
            onMultipleFieldChange("category", newValue)
          }
          onInputChange={(e, newInputValue) =>
            onMultipleFieldChange("categoryInput", newInputValue)
          }
        />

        {formState.relatedPersonList.map((relatedPerson, index) => (
          <div key={index} style={{ marginLeft: 15 }}>
            <DiscountTypesDropDownList
              fullWidth
              label="Saistītās personas kategorija"
              value={relatedPerson.category}
              onChange={(e) => onArrayFieldChange(index, "category", e)}
              name="relatedPersonList"
              variant="outlined"
              helperText={formErrors.relatedPersonCategory}
              error={Boolean(formErrors.relatedPersonCategory)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Saistītās personas personas kods"
              fullWidth
              name="relatedPersonList"
              autoComplete="off"
              value={relatedPerson.personCode}
              onChange={(e) => onArrayFieldChange(index, "personCode", e)}
              helperText={formErrors.relatedPersonPersonCode}
              error={Boolean(formErrors.relatedPersonPersonCode)}
              inputProps={{ maxLength: 12 }}
              InputProps={{
                endAdornment: (
                  <CancelIcon
                    className={classes.closeIcon}
                    color="primary"
                    onClick={() => {
                      setFormState({
                        ...formState,
                        relatedPersonList: formState.relatedPersonList.filter(
                          (el, ind) => index !== ind
                        ),
                      });
                    }}
                  />
                ),
              }}
            />
          </div>
        ))}

        {formState.category.length > 0 && (
          <Box
            display="flex"
            flexDirection="column"
            my={1}
            alignItems="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setFormState({
                  ...formState,
                  relatedPersonList: [
                    ...formState.relatedPersonList,
                    {
                      personCode: "",
                      category: null,
                    },
                  ],
                });
              }}
              endIcon={
                <Tooltip title="Vismaz viena persona obligāti ir jānorāda kategorijām “Aizgādnis personai ar invaliditāti”, “Saistītā persona bērnam ar invaliditāti” un vismaz 3 personas ir jānorāda kategorijai “Daudzbērnu ģimene”. Pārējām kategorijām saistītās personas var nenorādīt, taču ja tādas ir, ir jānorāda.">
                  <InfoIcon />
                </Tooltip>
              }
            >
              pievienot saistītās personas
            </Button>
          </Box>
        )}

        <DatePicker
          fullWidth
          label="Datums līdz"
          value={formState.period}
          onChange={(date) =>
            setFormState({
              ...formState,
              period: date,
            })
          }
          minDate={moment()}
          helperText={formErrors.period}
          error={Boolean(formErrors.period)}
        />

        <TextField
          variant="outlined"
          margin="dense"
          multiline
          rows={2}
          label="Iemesls"
          name="comment"
          autoComplete="off"
          fullWidth
          onChange={onFieldChange}
          value={formState.comment}
        />
      </DialogContent>
      <DialogActions>
        <Box px={2}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={onSubmit}
            color="primary"
          >
            SAGLABĀT
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
