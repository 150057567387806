import { useState } from 'react'
import { makeStyles, Typography, Box, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FileExportIcon from 'mdi-material-ui/FileExport'
import { useQuery } from 'react-query'
import moment from 'moment'

import DatePicker from '../../elements/DatePicker'
import { ALL_PROVIDERS_ID } from '../../../constants/Constants'
import ProvidersDropDownList from '../../elements/ProvidersDropDownList'
import ResetButton from '../../elements/ResetButton'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import { merchantSupportColumns } from './columns'
import { fetchOverviewsProviderData } from '../../../api/API'
import { xlsxExporter, sortOverviewDataByDate } from '../../../utils/utils'
import { centsToEuros, formatFloat } from '../../../utils/Number'

const useStyles = makeStyles((theme) => ({
  overviewTitle: {
    color: theme.palette.primary.main,
  },
  formControl: {
    minWidth: 150,
    marginRight: 24,
  },
  search: {
    minWidth: 150,
  },
  resetButton: {
    margin: '0 8px',
  },
  searchButton: {
    marginTop: '8px',
  },
}))

export const MerchantSupport = () => {
  const classes = useStyles()
  const [merchantSupportTableData, setMerchantSupportTableData] = useState([])
  const [provider, setProvider] = useState(ALL_PROVIDERS_ID)
  const [selectedDateFrom, setSelectedDateFrom] = useState(moment().startOf('month'))
  const [selectedDateTo, setSelectedDateTo] = useState(moment().endOf('month'))

  const resetFilters = () => {
    setProvider(ALL_PROVIDERS_ID)
    setSelectedDateFrom(moment().startOf('month'))
    setSelectedDateTo(moment().endOf('month'))
  }

  const { refetch, isFetching } = useQuery(
    'merchant-support',
    () => {
      const formattedDateFrom = moment(selectedDateFrom).format('DD.MM.YYYY')
      const formattedDateTo = moment(selectedDateTo).format('DD.MM.YYYY')

      return fetchOverviewsProviderData({
        ProviderId: provider === ALL_PROVIDERS_ID ? null : provider,
        ReportFrom: formattedDateFrom,
        ReportTill: formattedDateTo,
      })
    },
    {
      onSuccess: (data) => {
        const filteredData = sortOverviewDataByDate(data, 'ReportMonth')
        setMerchantSupportTableData(filteredData)
      },
      onError: () => {
        setMerchantSupportTableData([])
      },
      refetchOnWindowFocus: false,
    }
  )

  const exportTable = () => {
    const columnTitles = merchantSupportColumns.map((column) => ({ title: column.title }))
    const data = [columnTitles.map((column) => `${column.title}`)]
    merchantSupportTableData.forEach(
      ({ ProviderName, ReportMonth, DiscountAmount, PayoutAmount }) => {
        const formattedLine = [
          ProviderName,
          moment(new Date(ReportMonth)).format('MM.YYYY'),
          formatFloat(centsToEuros(DiscountAmount)),
          formatFloat(centsToEuros(PayoutAmount)),
        ]
        data.push(formattedLine)
      }
    )

    const fileNameDate = `${moment(selectedDateFrom).format('MM.YYYY')} - ${moment(
      selectedDateTo
    ).format('MM.YYYY')}`
    const fileName = `Piešķirtais_un_izmantotais_atbalsts_sadalījumā_pa_tirgotājiem (${fileNameDate})`

    xlsxExporter(data, fileName)
  }

  return (
    <>
      <Typography className={classes.overviewTitle} variant="h6">
        Piešķirtais un izmantotais atbalsts sadalījumā pa tirgotājiem
      </Typography>
      <Box display="flex" flexWrap="wrap" margin="16px 0">
        <ProvidersDropDownList
          className={classes.formControl}
          margin="none"
          label="Tirgotājs"
          value={provider}
          onChange={({ target: { value } }) => setProvider(value)}
        />
        <Box mr={2}>
          <DatePicker
            className={classes.search}
            value={selectedDateFrom}
            inputVariant="standard"
            format="MMMM YYYY"
            views={['year', 'month']}
            openTo="year"
            label="Periods no"
            InputProps={{ readOnly: true }}
            margin="none"
            onChange={(date) => {
              setSelectedDateFrom(date)
            }}
            openOnInputClick
          />
        </Box>
        <Box mr={2}>
          <DatePicker
            className={classes.search}
            value={selectedDateTo}
            inputVariant="standard"
            format="MMMM YYYY"
            views={['year', 'month']}
            openTo="year"
            label="Periods līdz"
            InputProps={{ readOnly: true }}
            margin="none"
            onChange={(date) => {
              setSelectedDateTo(date)
            }}
            openOnInputClick
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            onClick={refetch}
            color="primary"
            variant="contained"
            className={classes.searchButton}
            startIcon={<SearchIcon />}
          >
            Meklēt
          </Button>
          <ResetButton className={classes.resetButton} onClick={resetFilters} />
        </Box>
        <Box mt={2} ml="auto">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileExportIcon />}
            onClick={exportTable}
          >
            Eksports
          </Button>
        </Box>
      </Box>
      <CustomizedMaterialTable
        columns={merchantSupportColumns}
        data={merchantSupportTableData || []}
        options={{
          defaultExpanded: true,
        }}
        isLoading={isFetching}
      />
    </>
  )
}
