import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'
import moment from 'moment'

import { getPeriods } from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
  formClass: {
    width: '100%',
  },
  periodsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectAllButton: {
    width: '100%',
    marginBottom: '12px',
  },
  monthsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '10px',
  },
  monthButton: {
    width: '100%',
  },
}))

const periods = getPeriods(11)

export default function PeriodsTagList({
  margin = 'dense',
  className,
  fullWidth,
  size = 'medium',
  variant = 'standard',
  helperText,
  error,
  onChange,
}) {
  const classes = useStyles()

  const [selectedMonths, setSelectedMonths] = useState([])

  const handleSelectAllMonths = () => {
    const allMonthsValues = periods.map(({ value }) => moment(value).format('DD.MM.YYYY'))
    setSelectedMonths(allMonthsValues)
    onChange(allMonthsValues)
  }

  const handleSelectSingleMonth = (formattedDate) => {
    let newSelectedMonths = []
    if (selectedMonths.includes(formattedDate)) {
      newSelectedMonths = selectedMonths.filter((el) => el !== formattedDate)
    } else {
      newSelectedMonths = [...selectedMonths, formattedDate]
    }
    setSelectedMonths(newSelectedMonths)
    onChange(newSelectedMonths)
  }

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      className={[classes.formClass, className].join(' ')}
      margin={margin}
      error={error}
    >
      <Box className={classes.periodsContainer}>
        <Button
          className={classes.selectAllButton}
          color="primary"
          variant="contained"
          onClick={handleSelectAllMonths}
        >
          Atzīmēt visus mēnešus
        </Button>
        <Box className={classes.monthsGrid}>
          {periods.map(({ label, value }) => {
            const formattedDate = moment(value).format('DD.MM.YYYY')
            return (
              <Box className={classes.monthBox} key={formattedDate}>
                <Button
                  color="primary"
                  variant={selectedMonths.includes(formattedDate) ? 'contained' : 'outlined'}
                  className={classes.monthButton}
                  onClick={() => handleSelectSingleMonth(formattedDate)}
                >
                  {label}
                </Button>
              </Box>
            )
          })}
        </Box>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
