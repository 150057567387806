import React from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link, useHistory } from "react-router-dom";
import ModalProfile from "../views/ModalProfile";
import { useMutation } from "react-query";
import { logout } from "../../api/API";
import { useSnackBar } from "../providers/SnackBarProvider";

const useStyles = makeStyles((theme) => ({
  logoutBox: {
    cursor: "pointer",
  },
}));

export default function Header() {
  const classes = useStyles();
  const [openProfileModal, setOpenProfileModal] = React.useState(false);
  const history = useHistory();
  const user = `${localStorage.getItem("firstName")} ${localStorage.getItem(
    "lastName"
  )}`;
  const { showSnackBar } = useSnackBar();
  const [logoutMutation] = useMutation(logout, {
    onSuccess: (response) => {
      localStorage.clear();
      history.replace("/login");
    },
    onError: (error) => {
      showSnackBar({
        severity: 'error',
        text: `kļūda: ${error.response?.data}`,
      });
    },
  });

  return (
    <>
      <ModalProfile
        onClose={() => setOpenProfileModal(false)}
        open={openProfileModal}
      />
      <Box
        my={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Link to="/">
            <Typography color="primary" variant="h3" fontWeight="bold">
              ALDIS
            </Typography>
            <Typography color="primary" variant="body2">
              Aizsargātā lietotāja datu informācijas sistēma
            </Typography>
          </Link>
        </Box>
        <Box
          className={classes.logoutBox}
          display="flex"
          alignItems="center"
          mr={1}
        >
          <Typography
            variant="body1"
            color="inherit"
            onClick={() => setOpenProfileModal(true)}
          >
            {user}
          </Typography>
          <ExitToAppIcon onClick={logoutMutation} style={{ marginLeft: 8 }} />
        </Box>
      </Box>
    </>
  );
}
