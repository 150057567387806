import { Typography, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  parentBox: {
    display: 'flex',
    marginTop: '12px',
  },
  rowBox: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '180px',
  },
  rowBoxData: {
    marginTop: '16px',
  },
})

export default function ParentRow({ person }) {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h6" color="primary">
        Vecāks
      </Typography>
      <Box className={classes.parentBox}>
        <Box className={classes.rowBox}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            Personas kods
          </Typography>
          <Box className={classes.rowBoxData}>
            <Typography variant="body2">{person.PersonCode}</Typography>
          </Box>
        </Box>
        <Box className={classes.rowBox}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            Vārds
          </Typography>
          <Box className={classes.rowBoxData}>
            <Typography variant="body2">{person.PersonFirstName}</Typography>
          </Box>
        </Box>
        <Box className={classes.rowBox}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            Uzvārds
          </Typography>
          <Box className={classes.rowBoxData}>
            <Typography variant="body2">{person.PersonLastName}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
