import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useQuery } from "react-query";
import { fetchDiscountTypes } from "../../api/API";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  select: {},
}));

export default function DiscountTypesDropDownList({
  label,
  multiSelect,
  helperText,
  error,
  fullWidth,
  margin = "normal",
  variant = "standard",
  size = "small",
  noFetch,
  name,
  selectFirst,
  ...props
}) {
  const { data = [] } = useQuery("discount-types", fetchDiscountTypes, {
    onSuccess: (_data) => {
      if (selectFirst && _data?.length > 0) {
        props.onChange({
          target: { value: _data[0].DiscountTypeId, name: name },
        });
      }
    },
    enabled: !noFetch,
  });
  const classes = useStyles();
  return (
    <FormControl
      className={classes.select}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      margin={margin}
      error={error}
    >
      {multiSelect ? (
        <Autocomplete
          multiple
          size={size}
          options={data}
          noOptionsText="Nav atbilstošu ierakstu"
          getOptionLabel={(option) => option.DiscountTypeDesc}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              name={name}
              {...params}
              variant="outlined"
              label={label}
              placeholder={label}
              error={error}
            />
          )}
          {...props}
        />
      ) : (
        <>
          <InputLabel>{label}</InputLabel>
          <Select name={name} {...props} label={label}>
            {data.map(({ DiscountTypeId, DiscountTypeDesc }) => (
              <MenuItem key={DiscountTypeId} value={DiscountTypeId}>
                {DiscountTypeDesc}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {error && 
        <Box ml={1}>
          <FormHelperText>{helperText}</FormHelperText>
        </Box>
      }
    </FormControl>
  );
}
