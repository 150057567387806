export const ADMIN_ID = 1
export const ALL_USERS_ID = -1
export const ALL_OPERATIONS_ID = -1
export const ALL_PROVIDERS_ID = -1
export const PERSON_CODE_LENGTH = 11
export const URL_PARAMS = {
  mustChangePassword: 'mustChangePassword',
  passwordChangeMessage: 'passwordChangeMessage',
}

export const REGEX_PASSWORD = /^((?=.*[A-Z])(?=.*[a-z])((?=.*\d)|(?=.*\W))\w.{8,})$/
export const REGEX_PERSON_CODE = /^([0-3]\d{5})-?\d{5}$/

export const MULTIPLE_CHILDREN_DISCOUNT_TYPE = 1
export const DISABLED_CHILDREN_DISCOUNT_TYPE = 5

// Hardcoded filter ids to custom categories
export const registersIds = new Set([2, 3, 4, 5, 6, 7])
export const contractsIds = new Set([8, 15, 17, 18])
export const supportIds = new Set([1, 9, 10, 11])
export const statusChangeIds = new Set([12, 13, 14, 16, 19])

export const defaultErrMsg = 'Šis lauks ir obligāts'
export const notEnoughCharError = 'Ievadītais EKK ir par īsu'
export const wrongValue = 'Vērtībai jābūt robežās no 0,01 līdz 99999,99'

export const add = {
  label: "Bilances palielināšana",
  value: "ADD"
}

export const remove = {
  label: "Bilances samazināšana",
  value: "REMOVE"
}

export const actionOptions = [add, remove]