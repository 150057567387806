export function formatFloat(number, fractionDigits = 2) {
  return parseFloat((number || 0).toFixed(fractionDigits))
}

export function formatNumber(number, fractionDigits = 2) {
  return (number || 0).toFixed(fractionDigits)
}

export function centsToEuros(cents = 0) {
  return cents / 100
}

export function eurosToCents(euros = 0) {
  return Math.round(euros * 100)
}
