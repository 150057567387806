import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import SignIn from "./components/views/SignIn";
import ThemeProvider from "./components/providers/ThemeProvider";
import Main from "./components/views/Main";
import SnackBarProvider from "./components/providers/SnackBarProvider";
import ReactQueryConfigProvider from "./components/providers/ReactQueryConfigProvider";

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function App() {
  return (
    <ReactQueryConfigProvider>
      <ThemeProvider>
        <SnackBarProvider>
          <Router>
            <Switch>
              <Route path="/login">
                <SignIn />
              </Route>
              <PrivateRoute path="/">
                <Main />
              </PrivateRoute>
            </Switch>
          </Router>
        </SnackBarProvider>
      </ThemeProvider>
    </ReactQueryConfigProvider>
  );
}
