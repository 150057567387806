import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from 'react-query'
import { fetchUserDetails } from '../../../api/API'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import { editUser } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { REGEX_PASSWORD } from '../../../constants/Constants'
import { defaultErrMsg } from '../../../constants/Constants'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 4,
  },
  button: {
    minWidth: 130,
  },
})

export default function ModalAddEditUser({ open, onClose, refetch, user }) {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon className={classes.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              LIETOTĀJS
            </Box>
          </DialogTitle>
          <Form onClose={onClose} refetch={refetch} user={user} />
        </Box>
      </Box>
    </Dialog>
  )
}

function Form({ onClose, refetch, user }) {
  const classes = useStyles()
  const isEditMode = Boolean(user)
  const { showSnackBar } = useSnackBar()
  //form data
  const [formState, setFormState] = React.useState({
    selectedUser: user,
    user: null,
    userLogin: '',
    userPass: '',
    firstName: '',
    lastName: '',
    userActive: 1,
  })
  //form errors
  const [formErrors, setFormErrors] = React.useState({})

  useQuery(
    [
      'user-by-id',
      {
        user,
      },
    ],
    (_, { user }) => {
      if (!isEditMode) return
      return fetchUserDetails({
        UserId: user.UserId,
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          setFormState({
            ...formState,
            user: data.UserId,
            userLogin: data.UserLogin,
            firstName: data.UserName,
            lastName: data.UserLastName,
            userActive: data.UserActive,
          })
        }
      },
    }
  )

  const [editUserMutation] = useMutation(
    ({ user, userLogin, userPass, firstName, lastName, userActive }) =>
      editUser({
        UserId: user,
        UserLogin: userLogin,
        UserPass: userPass,
        UserName: firstName,
        UserLastName: lastName,
        UserActive: userActive,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        refetch()
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        }),
    }
  )

  const onFieldChange = ({ target }) => setFormState({ ...formState, [target.name]: target.value })

  const isFormValid = () => {
    const { userLogin, userPass, firstName, lastName } = formState
    const _errors = []
    if (!userLogin) _errors['userLogin'] = defaultErrMsg
    if (!userPass.match(REGEX_PASSWORD) && !isEditMode)
      _errors['userPass'] =
        'Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam'
    if (!firstName) _errors['firstName'] = defaultErrMsg
    if (!lastName) _errors['lastName'] = defaultErrMsg
    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    editUserMutation(formState)
  }

  return (
    <>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="dense"
          label="Lietotājvārds"
          fullWidth
          name="userLogin"
          inputProps={{
            autoComplete: 'off',
          }}
          value={formState.userLogin}
          onChange={onFieldChange}
          helperText={formErrors.userLogin}
          error={Boolean(formErrors.userLogin)}
        />
        {!isEditMode && (
          <TextField
            variant="outlined"
            margin="dense"
            label="Parole"
            fullWidth
            type="password"
            inputProps={{
              autoComplete: 'new-password',
            }}
            name="userPass"
            value={formState.userPass}
            onChange={onFieldChange}
            helperText={formErrors.userPass}
            error={Boolean(formErrors.userPass)}
            InputProps={{
              endAdornment: (
                <Tooltip title="Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam">
                  <InfoIcon color="primary" />
                </Tooltip>
              ),
            }}
          />
        )}
        <TextField
          variant="outlined"
          margin="dense"
          label="Vārds"
          name="firstName"
          autoComplete="off"
          fullWidth
          onChange={onFieldChange}
          value={formState.firstName}
          helperText={formErrors.firstName}
          error={Boolean(formErrors.firstName)}
        />
        <TextField
          variant="outlined"
          margin="dense"
          name="lastName"
          label="Uzvārds"
          autoComplete="off"
          fullWidth
          onChange={onFieldChange}
          value={formState.lastName}
          helperText={formErrors.lastName}
          error={Boolean(formErrors.lastName)}
        />
      </DialogContent>
      <DialogActions>
        <Box px={2}>
          <Button className={classes.button} variant="contained" onClick={onSubmit} color="primary">
            SAGLABĀT
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
