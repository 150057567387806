import React from "react";
import { useQuery } from "react-query";
import {
    fetchInvalidsData,
} from "../../../api/API";
import moment from "moment";
import { addDashToPersonCode, normalizePersonCode } from "../../../utils/Misc";
import { PERSON_CODE_LENGTH } from "../../../constants/Constants";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";

export default function Invalids({ selectedPeriod, personCode }) {
    const [rows, setRows] = React.useState([]);
    const columnsVDEAVK = [
        {
            title: "Personas kods",
            field: "PersonCode",
            render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
        },
        { title: "Kategorija", field: "InvalidTypeName" },
        { title: "Statusa sākuma datums", field: "InvalidStatusFrom" },
        { title: "Statusa beigu datums", field: "InvalidStatusTill" },
        {
            title: "Saistītās personas personas kods",
            field: "GuardianCode",
            render: ({ GuardianCode }) => addDashToPersonCode(GuardianCode),
        },
        { title: "Saistītās personas vārds", field: "GuardianFirstName" },
        { title: "Saistītās personas uzvārds", field: "GuardianLastName" },
        { title: "Saistītā persona no", field: "GuardianStatusFrom" },
        { title: "Saistītā persona līdz", field: "GuardianStatusTill" },
    ];
    
    const { isLoading } = useQuery(
        [
            "invalids-data",
            {
                personCode,
                selectedPeriod,
            },
        ],
        (_, { personCode, selectedPeriod }) => {
            const normalizedPersonCode = normalizePersonCode(personCode);
            if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return;
            return fetchInvalidsData({
                PersonCode: normalizedPersonCode,
                ProviderId: null,
                InvalidStatusFrom: moment(selectedPeriod).format("DD.MM.YYYY"),
                InvalidStatusTill: moment(selectedPeriod)
                    .endOf("month")
                    .format("DD.MM.YYYY"),
            });
        },
        {
            onSuccess: (data) => {
                if (data) setRows(data);
                else setRows([]);
            },
            onError: (error) => {
                console.error(error);
                setRows([]);
            },
        }
    );

    return (
        <CustomizedMaterialTable
            columns={columnsVDEAVK}
            data={rows}
            isLoading={isLoading}
            options={{
                defaultExpanded: true,
            }}
        />
    );
}