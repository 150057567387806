import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CancelIcon from '@material-ui/icons/Cancel'
import grey from '@material-ui/core/colors/grey'
import { confirmable, createConfirmation } from 'react-confirm'
import TextField from '@material-ui/core/TextField'
import ThemeProvider from '../providers/ThemeProvider'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  button: {
    minWidth: 90,
  },
  smallText: {
    fontSize: 13,
    textAlign: 'center',
    color: grey[700],
  },
  text: {
    fontSize: 15,
    textAlign: 'center',
    marginBottom: '12px',
  },
})

function ConfirmModal({
  show,
  proceed,
  text,
  description,
  fields = [],
}) {
  const classes = useStyles()
  const [fieldsState, setFieldsState] = React.useState(fields.map((f) => ({ ...f, value: '' })))
  const [formErrors, setFormErrors] = React.useState({})
  const isFormValid = () => {
    const _errors = []
    fieldsState
      .filter((x) => x.regex && x.errorText)
      .forEach(({ id, value, regex, errorText }) => {
        if (!value.match(regex)) {
          _errors[id] = errorText
        }
      })

    setFormErrors(_errors)
    return Object.keys(_errors).length === 0
  }

  return (
    <ThemeProvider>
      <Dialog open={show}>
        <Box pb={2}>
          <Box mt={1} mr={2} display="flex" justifyContent="flex-end">
            <CancelIcon
              className={classes.closeIcon}
              color="primary"
              onClick={() =>
                proceed({
                  ok: false,
                })
              }
            />
          </Box>
          <Box px={2}>
            <DialogContent>
              <Box>
                {text ? <div className={classes.text}>{text}</div> : null}
                {description && (
                  <div
                    className={classes.smallText}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </Box>
              {/* fields with data to return after user confirmation (proceed) */}
              {fieldsState.length > 0 && (
                <Box mt={1}>
                  {fieldsState.map(({ infoText, ...props }) => (
                    <TextField
                      key={props.id}
                      fullWidth
                      margin="normal"
                      size="small"
                      variant="outlined"
                      onChange={({ target }) =>
                        setFieldsState((_fieldsState) =>
                          _fieldsState.map((f) =>
                            f.id === props.id ? { ...f, value: target.value } : f
                          )
                        )
                      }
                      error={Boolean(formErrors[props.id])}
                      helperText={formErrors[props.id]}
                      InputProps={{
                        endAdornment: infoText && (
                          <Tooltip title={infoText}>
                            <InfoIcon color="primary" />
                          </Tooltip>
                        ),
                      }}
                      {...props}
                    />
                  ))}
                </Box>
              )}
              <Box mt={2} mb={1} display="flex" justifyContent="center">
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => {
                    if (!isFormValid()) return
                    proceed({
                      ok: true,
                      fields: fieldsState,
                    })
                  }}
                  color="primary"
                >
                  Labi
                </Button>
                <Button
                  style={{ marginLeft: 20 }}
                  className={classes.button}
                  variant="contained"
                  onClick={() =>
                    proceed({
                      ok: false,
                    })
                  }
                  color="primary"
                >
                  Atcelt
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  )
}

export const confirmDialog = createConfirmation(confirmable(ConfirmModal))
