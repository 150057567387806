import React from 'react';
import MuiSnackbar  from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackBarContext = React.createContext({
});

export const useSnackBar = () => {
    const context = React.useContext(SnackBarContext);
    if (!context) {
        throw new Error('useSnackBar must be used within a SnackBarProvider')
    }
    return context
}

const SnackBarProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('success')
    const [text, setText] = React.useState('');
    const openSnackBar = ({
        severity = 'success',
        text
    } = {}) => {
        setSeverity(severity);
        setText(text);
        setOpen(true);
    }
    return (
        <SnackBarContext.Provider value={{
            showSnackBar: openSnackBar
        }}>
            <SnackBar
                text={text}
                severity={severity}
                open={open}
                onClose={() => setOpen(false)}
            />
            {children}
        </SnackBarContext.Provider>)
};

export default SnackBarProvider

const SnackBar = ({ severity = 'success', text, ...props }) => {
    const _text = text || (severity === 'success' ? "Saglabāts!" : "kļūda!")
    return (
        <MuiSnackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000}  {...props}>
            <Alert severity={severity}>
                <div style={{
                    fontSize: 14
                }}>
                    {_text}
                </div>
            </Alert>
        </MuiSnackbar>
    )
}

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}
