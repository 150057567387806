import axios from 'axios'

// axios configuration
const BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'https://aldis.mtx.lv/api'
axios.defaults.baseURL = BASE_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

const UNAUTHORIZED_CODE = 401
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response
    if (status === UNAUTHORIZED_CODE) {
      localStorage.clear()
      window.location = '/#/login'
    }
    return Promise.reject(error)
  }
)

// endpoints
const LOGIN_ENDPOINT = '/login'
const LOGOUT_ENDPOINT = '/logout'

const USER_DATA_ENDPOINT = '/users/list'
const USER_DETAILS_ENDPOINT = '/users/details'
const USER_EDIT_ENDPOINT = '/users/edit'
const USER_DELETE_ENDPOINT = '/users/delete'
const USER_SET_PASSWORD_ENDPOINT = '/users/set_password'

const PERSON_DATA_ENDPOINT = '/persons/data'
const PERSON_DATA_LIST_ENDPOINT = '/persons/data_list'
const PERSON_DATA_TYPES_ENDPOINT = 'persons/data_types'
const MONITORING_DATA_ENDPOINT = '/monitoring/data'
const DELETED_PERSONS_DATA_ENDPOINT = '/persons/deleted'
const MONITORING_STATUSES_ENDPOINT = '/monitoring/status'
const MONITORING_PROCESS_STATUS_ENDPOINT = '/monitoring/main_process_status'

const PERSON_DELETE_ENDPOINT = '/persons/delete'
const PERSON_RESTORE_ENDPOINT = '/persons/restore'
const PERSON_STATUS_SET_ENDPOINT = '/persons/set_status'
const DELETE_PERSON_STATUS_ENDPOINT = '/persons/delete_status'

const DISCOUNT_EDIT_ENDPOINT = '/discounts/edit'
const DISCOUNT_DELETE_ENDPOINT = '/discounts/delete'
const DISCOUNT_TYPES_ENDPOINT = '/discounts/types'
const DISCOUNT_DATA_ENDPOINT = '/discounts/data'
const DISCOUNT_RECALC_SET_ENDPOINT = '/discounts/recalc/set'
const DISCOUNT_CONTRACT_SET_ENDPOINT = '/discounts/set_data_contract_nr'
const DISCOUNT_CONTRACT_GET_ENDPOINT = '/discounts/get_data_contract_nr'

const PROVIDER_DISCOUNT_DATA_ENDPOINT = '/providers/discounts'
const PROVIDER_AWARDED_DISCOUNT_DATA_ENDPOINT = '/providers/discounts_data'
const PROVIDER_DATA_ENDPOINT = '/providers/data'
const PROVIDER_EDIT_ENDPOINT = '/providers/edit'
const PROVIDER_DELETE_ENDPOINT = '/providers/delete'
const PROVIDER_TYPES_ENDPOINT = '/providers/types'

const AUDIT_DATA_ENDPOINT = '/audits/data'
const AUDIT_TYPES_ENDPOINT = '/audits/types'

const INVALIDS_DATA_ENDPOINT = '/invalids/data'
const INVALIDS_TYPES_ENDPOINT = '/invalids/types'

const FAMILY_DATA_ENDPOINT = '/persons/family_members'
const LOW_INCOME_FAMILY_DATA_ENDPOINT = '/persons/low_income'
const NON_PAYMENT_MAINTENANCE_ENDPOINT = '/persons/ugfa'
const SIF_PERSONS_INTEGRATION_DATA_ENDPOINT = '/persons/sif'

const PRIVILEGES_PRIORITY_DATA_ENDPOINT = '/persons/privilege_priority/data'
const PRIVILEGES_PRIORITY_EDIT_ENDPOINT = '/persons/privilege_priority/set'

const CONTRACT_DATA_ENDPOINT = '/contracts/data'
const CONTRACT_PROVIDER_DATA_ENDPOINT = '/contracts/provider_data'
const CONTRACT_EDIT_ENDPOINT = '/contracts/edit'
const CONTRACT_DELETE_ENDPOINT = '/contracts/delete'
const EXCHANGE_DATA_ENDPOINT = '/exchange/data'

const PRIVILEGES_DATA_ENDPOINT = '/persons/privilege_list'

const OVERVIEWS_MONTHLY_DATA = '/overviews/monthly'
const OVERVIEWS_PERSON_DATA = '/overviews/person'
const OVERVIEWS_PROVIDER_DATA = '/overviews/provider'

export function login(data) {
  return axios.post(LOGIN_ENDPOINT, data)
}

export function logout() {
  return axios.put(LOGOUT_ENDPOINT)
}

export function setPassword(data) {
  return axios.post(USER_SET_PASSWORD_ENDPOINT, data)
}

export async function fetchOverviewsMonthlyData(payload) {
  const { data } = await axios.post(OVERVIEWS_MONTHLY_DATA, payload)
  return data
}

export async function fetchOverviewsPersonData(payload) {
  const { data } = await axios.post(OVERVIEWS_PERSON_DATA, payload)
  return data
}

export async function fetchOverviewsProviderData(payload) {
  const { data } = await axios.post(OVERVIEWS_PROVIDER_DATA, payload)
  return data
}

export async function fetchPersonData(_data) {
  const { data } = await axios.post(PERSON_DATA_ENDPOINT, _data)
  return data
}

export async function fetchPersonDataList(_data) {
  const { data } = await axios.post(PERSON_DATA_LIST_ENDPOINT, _data)
  return data
}

export async function fetchPersonDataTypesList() {
  const { data } = await axios.get(PERSON_DATA_TYPES_ENDPOINT)
  return data
}

export async function fetchFamilyData(_data) {
  const { data } = await axios.post(FAMILY_DATA_ENDPOINT, _data)
  return data
}

export async function fetchIntegrationData(_data) {
  const { data } = await axios.post(SIF_PERSONS_INTEGRATION_DATA_ENDPOINT, _data)
  return data
}

export async function fetchLowIncomeFamilyData(_data) {
  const { data } = await axios.post(LOW_INCOME_FAMILY_DATA_ENDPOINT, _data)
  return data
}

export async function fetchNonPaymentMaintenanceData(_data) {
  const { data } = await axios.post(NON_PAYMENT_MAINTENANCE_ENDPOINT, _data)
  return data
}

export async function fetchMonitoringData(_data) {
  const { data } = await axios.post(MONITORING_DATA_ENDPOINT, _data)
  return data
}

export async function fetchMonitoringStatuses() {
  const { data } = await axios.get(MONITORING_STATUSES_ENDPOINT)
  return data
}

export async function setMonitoringStatusProcess(_data) {
  const { data } = await axios.post(MONITORING_PROCESS_STATUS_ENDPOINT, _data)
  return data
}

export async function fetchDeletedPersonsData(_data) {
  const { data } = await axios.post(DELETED_PERSONS_DATA_ENDPOINT, _data)
  return data
}

export function deletePerson(data) {
  return axios.post(PERSON_DELETE_ENDPOINT, data)
}

export function restorePerson(data) {
  return axios.post(PERSON_RESTORE_ENDPOINT, data)
}

export function fetchPersonsChildren(data) {
  return axios.post(PRIVILEGES_PRIORITY_DATA_ENDPOINT, data)
}

export function setPersonsChildren(data) {
  return axios.post(PRIVILEGES_PRIORITY_EDIT_ENDPOINT, data)
}

export async function fetchDiscountTypes() {
  const { data } = await axios.get(DISCOUNT_TYPES_ENDPOINT)
  return data
}

export async function fetchDiscountData() {
  const { data } = await axios.get(DISCOUNT_DATA_ENDPOINT)
  return data
}

export async function fetchProviderDiscountData(_data) {
  const { data } = await axios.post(PROVIDER_DISCOUNT_DATA_ENDPOINT, _data)
  return data
}

export async function fetchProviderAwardedDiscountData(_data) {
  const { data } = await axios.post(PROVIDER_AWARDED_DISCOUNT_DATA_ENDPOINT, _data)
  return data
}

export function editDiscount(data) {
  return axios.post(DISCOUNT_EDIT_ENDPOINT, data)
}

export function deleteDiscount(data) {
  return axios.post(DISCOUNT_DELETE_ENDPOINT, data)
}

export function setDiscountRecalc(data) {
  return axios.post(DISCOUNT_RECALC_SET_ENDPOINT, data)
}

export function setContractNumber(data) {
  return axios.post(DISCOUNT_CONTRACT_SET_ENDPOINT, data)
}

export async function getContractData(_data) {
  const { data } = await axios.post(DISCOUNT_CONTRACT_GET_ENDPOINT, _data)
  return data
}

export async function fetchInvalidsTypes() {
  const { data } = await axios.get(INVALIDS_TYPES_ENDPOINT)
  return data
}

export async function fetchInvalidsData(_data) {
  const { data } = await axios.post(INVALIDS_DATA_ENDPOINT, _data)
  return data
}

export async function fetchProviderTypes() {
  const { data } = await axios.get(PROVIDER_TYPES_ENDPOINT)
  return data
}

export async function fetchProviderData() {
  const { data } = await axios.get(PROVIDER_DATA_ENDPOINT)
  return data
}

export function editProvider(data) {
  return axios.post(PROVIDER_EDIT_ENDPOINT, data)
}

export function deleteProvider(data) {
  return axios.post(PROVIDER_DELETE_ENDPOINT, data)
}

export async function fetchUserData() {
  const { data } = await axios.get(USER_DATA_ENDPOINT)
  return data
}

export async function fetchUserDetails(_data) {
  const { data } = await axios.post(USER_DETAILS_ENDPOINT, _data)
  return data
}

export async function editUser(data) {
  return axios.post(USER_EDIT_ENDPOINT, data)
}

export function deleteUser(data) {
  return axios.post(USER_DELETE_ENDPOINT, data)
}

export async function fetchAuditData(_data) {
  const { data } = await axios.post(AUDIT_DATA_ENDPOINT, _data)
  return data
}

export async function fetchAuditTypes() {
  const { data } = await axios.get(AUDIT_TYPES_ENDPOINT)
  return data
}

export async function fetchContractData(_data) {
  const { data } = await axios.post(CONTRACT_DATA_ENDPOINT, _data)
  return data
}

export async function fetchContractProviderData(_data) {
  const { data } = await axios.post(CONTRACT_PROVIDER_DATA_ENDPOINT, _data)
  return data
}

export function editContract(data) {
  return axios.post(CONTRACT_EDIT_ENDPOINT, data)
}

export function deleteContract(data) {
  return axios.post(CONTRACT_DELETE_ENDPOINT, data)
}

export async function fetchExchangeData(_data) {
  const { data } = await axios.post(EXCHANGE_DATA_ENDPOINT, _data)
  return data
}

export async function fetchPrivilegesData(_data) {
  const { data } = await axios.post(PRIVILEGES_DATA_ENDPOINT, _data)
  return data
}

export function setPersonStatus(data) {
  return axios.post(PERSON_STATUS_SET_ENDPOINT, data)
}

export function deletePersonStatus(data) {
  return axios.post(DELETE_PERSON_STATUS_ENDPOINT, data)
}
