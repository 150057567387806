import {
  Typography,
  Box,
  withStyles,
  Checkbox,
  makeStyles,
  Button,
  useTheme,
} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const ThemedCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}))((props) => <Checkbox color="default" {...props} />)

const useStyles = makeStyles({
  parentBoxItems: {
    display: 'flex',
    marginTop: '12px',
  },
  title: {
    marginTop: '16px',
  },
  rowBoxCheckbox: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  checkbox: {
    padding: 0,
    marginTop: '8px',
    marginRight: '22px',
  },
  rowBox: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '120px',
  },
  rowBoxData: {
    marginTop: '16px',
  },
  personCodeButton: {
    padding: '0',
    fontWeight: '400',
    borderRadius: 0,
  },
})

export default function ChildrenRows({
  title,
  person,
  childrenData,
  childrenFormState,
  handleChildrenCheckbox,
  onManualChildrenOpen,
  isFormDisabled,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const titleColor = isFormDisabled ? theme.palette.disabled.main : theme.palette.primary.main
  const columnHeaderColor = isFormDisabled ? theme.palette.disabled.main : theme.palette.common

  return (
    <>
      <Typography variant="h6" style={{ color: titleColor }} className={classes.title}>
        {title}
      </Typography>
      {childrenData.map((child, index) => {
        const childColor = isFormDisabled
          ? theme.palette.disabled.main
          : child.LinkPersonCode === person.PersonCode
          ? theme.palette.primary.main
          : theme.palette.common

        return (
          <Box key={index} className={classes.parentBoxItems}>
            <Box className={classes.rowBoxCheckbox}>
              <ThemedCheckbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                className={classes.checkbox}
                checked={childrenFormState.RelatedPersonList.some(
                  (person) => person.PersonCode === child.PersonCode
                )}
                onChange={() => handleChildrenCheckbox(child.PersonCode)}
                disabled={isFormDisabled}
                size="large"
                {...(index === 0 && {
                  style: { marginTop: '24px' },
                })}
              />
            </Box>
            <Box className={classes.rowBox}>
              {index === 0 ? (
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', color: columnHeaderColor }}
                >
                  Personas kods
                </Typography>
              ) : null}
              <Box className={classes.rowBoxData}>
                <Typography style={{ color: childColor }} variant="body2">
                  {child.PersonCode}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.rowBox}>
              {index === 0 ? (
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', color: columnHeaderColor }}
                >
                  Vārds
                </Typography>
              ) : null}
              <Box className={classes.rowBoxData}>
                <Typography style={{ color: childColor }} variant="body2">
                  {child.PersonOtherNames
                    ? `${child.PersonFirstName} ${child.PersonOtherNames}`
                    : child.PersonFirstName}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.rowBox}>
              {index === 0 ? (
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', color: columnHeaderColor }}
                >
                  Uzvārds
                </Typography>
              ) : null}
              <Box className={classes.rowBoxData}>
                <Typography style={{ color: childColor }} variant="body2">
                  {child.PersonLastName}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.rowBox}>
              {index === 0 ? (
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', color: columnHeaderColor }}
                >
                  Piesaistīts
                </Typography>
              ) : null}
              <Box className={classes.rowBoxData}>
                {!child.LinkPersonCode ? (
                  <Typography variant="body2" style={{ color: childColor }}>
                    -
                  </Typography>
                ) : person.PersonCode === child.LinkPersonCode ? (
                  <Typography variant="body2" style={{ color: childColor }}>
                    {child.LinkPersonCode}
                  </Typography>
                ) : (
                  <Button
                    className={classes.personCodeButton}
                    style={{
                      background: 'transparent',
                      color: childColor,
                      borderBottom: '2px dotted',
                      borderBottomColor: theme.palette.primary.main,
                    }}
                    onClick={() => onManualChildrenOpen(child.LinkPersonCode)}
                    disableRipple
                  >
                    {child.LinkPersonCode}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
    </>
  )
}
