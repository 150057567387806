import moment from 'moment'

import { addDashToPersonCode } from '../../../utils/Misc'
import { formatNumber, centsToEuros } from '../../../utils/Number'

export const alBalanceColumns = [
  {
    title: 'Tirgotājs',
    field: 'ProviderName',
  },
  {
    title: 'Mēnesis, gads',
    field: 'ReportMonth',
    render: ({ ReportMonth }) => moment(new Date(ReportMonth)).format('MM.YYYY'),
  },
  {
    title: 'Līguma Nr. (piešķirts)',
    field: 'DiscountContract',
  },
  {
    title: 'Piešķirtais atbalsts, EUR',
    field: 'DiscountAmount',
    render: ({ DiscountAmount }) => formatNumber(centsToEuros(DiscountAmount)),
  },
  {
    title: 'Līguma Nr. (izmantots)',
    field: 'PayoutContract',
  },
  {
    title: 'Izmantotais atbalsts, EUR',
    field: 'PayoutAmount',
    render: ({ PayoutAmount }) => formatNumber(centsToEuros(PayoutAmount)),
  },
]

export const alSupportColumns = [
  {
    title: 'Tirgotājs',
    field: 'ProviderName',
  },
  {
    title: 'Mēnesis, gads',
    field: 'ReportMonth',
    render: ({ ReportMonth }) => moment(new Date(ReportMonth)).format('MM.YYYY'),
  },
  {
    title: 'Personas kods',
    field: 'PersonCode',
    render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
  },
  {
    title: 'Piešķirtais atbalsts, EUR',
    field: 'DiscountAmount',
    render: ({ DiscountAmount }) => formatNumber(centsToEuros(DiscountAmount)),
  },
  {
    title: 'Izmantotais atbalsts, EUR',
    field: 'PayoutAmount',
    render: ({ PayoutAmount }) => formatNumber(centsToEuros(PayoutAmount)),
  },
]

export const merchantSupportColumns = [
  {
    title: 'Tirgotājs',
    field: 'ProviderName',
  },
  {
    title: 'Mēnesis, gads',
    field: 'ReportMonth',
    render: ({ ReportMonth }) => moment(new Date(ReportMonth)).format('MM.YYYY'),
  },
  {
    title: 'Piešķirtais atbalsts, EUR',
    field: 'DiscountAmount',
    render: ({ DiscountAmount }) => formatNumber(centsToEuros(DiscountAmount)),
  },
  {
    title: 'Izmantotais atbalsts, EUR',
    field: 'PayoutAmount',
    render: ({ PayoutAmount }) => formatNumber(centsToEuros(PayoutAmount)),
  },
]
