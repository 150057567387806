import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import DatePicker from "../../elements/DatePicker";
import DiscountTypesDropDownList from "../../elements/DiscountTypesDropDownList";
import { useMutation } from "react-query";
import { editDiscount } from "../../../api/API";
import { useSnackBar } from "../../providers/SnackBarProvider";
import moment from "moment";
import { centsToEuros, eurosToCents } from "../../../utils/Number";
import { defaultErrMsg } from "../../../constants/Constants";

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  dialogTitle: {
    paddingTop: 0,
    textTransform: 'uppercase',
  },
  button: {
    minWidth: 130,
  },
});

export default function ModalAddEditDiscount({ discount, onClose }) {
  const classes = useStyles();
  const { showSnackBar } = useSnackBar();
  const isEditMode = Boolean(discount);
  //form data
  const [formState, setFormState] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [editDiscountMutation] = useMutation(
    ({ id, type, discount, dateFrom, dateTo }) =>
      editDiscount({
        DiscountId: id,
        DiscountType: type,
        DiscountSum: eurosToCents(Number(discount)),
        DiscountCurrency: "EUR",
        DiscountValidFrom: dateFrom
          ? moment(dateFrom).format("DD.MM.YYYY")
          : "",
        DiscountValidTill: dateTo ? moment(dateTo).format("DD.MM.YYYY") : "",
      }),
    {
      onSuccess: () => {
        showSnackBar();
        onClose();
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        }),
    }
  );

  React.useEffect(() => {
    setFormState({
      id: isEditMode ? discount?.DiscountId : null,
      type: discount?.DiscountType,
      discount: discount?.DiscountSum ? centsToEuros(discount.DiscountSum) : "",
      dateFrom: discount?.DiscountValidFrom
        ? moment(discount.DiscountValidFrom, "DD.MM.YYYY").toDate()
        : null,
      dateTo: discount?.DiscountValidTill
        ? moment(discount.DiscountValidTill, "DD.MM.YYYY").toDate()
        : null,
    });
  }, [discount]); // eslint-disable-line react-hooks/exhaustive-deps
  const onFieldChange = ({ target }) =>
    setFormState({ ...formState, [target.name]: target.value });

  const isFormValid = () => {
    const { type, discount, dateFrom } = formState;
    const _errors = [];

    if (!type) _errors["type"] = defaultErrMsg;
    if (!dateFrom) _errors["dateFrom"] = defaultErrMsg;
    if (!discount) _errors["discount"] = defaultErrMsg;

    setFormErrors(_errors);

    return Object.keys(_errors).length === 0;
  };

  const onSubmit = () => {
    if (!isFormValid()) return;

    editDiscountMutation(formState);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Kategorija
            </Box>
          </DialogTitle>
          <DialogContent>
            <DiscountTypesDropDownList
              value={formState.type}
              onChange={onFieldChange}
              fullWidth
              name="type"
              label="Kategorija"
              margin="dense"
              variant="outlined"
              helperText={formErrors.type}
              error={Boolean(formErrors.type)}
            />

            <TextField
              variant="outlined"
              margin="dense"
              label="Atbalsta apmērs (EUR)"
              fullWidth
              name="discount"
              type="number"
              autoComplete="off"
              value={formState.discount}
              onChange={onFieldChange}
              helperText={formErrors.discount}
              error={Boolean(formErrors.discount)}
            />
            <Box display="flex" justifyContent="space-between">
              <DatePicker
                label="No"
                value={formState.dateFrom}
                error={Boolean(formErrors.dateFrom)}
                onChange={(date) =>
                  setFormState({
                    ...formState,
                    dateFrom: date,
                  })
                }
              />
              <DatePicker
                label="Līdz"
                value={formState.dateTo}
                onChange={(date) =>
                  setFormState({ ...formState, dateTo: date })
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                SAGLABĀT
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}
