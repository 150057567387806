import React from 'react'
import { createMuiTheme, ThemeProvider as Provider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009999',
    },
    secondary: {
      main: '#cb6464',
    },
    disabled: {
      main: '#bdbdbd',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
      },
    },
  },
})

export default function ThemeProvider({ children }) {
  return <Provider theme={theme}>{children}</Provider>
}
