import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { useQuery } from 'react-query'
import { ALL_USERS_ID } from '../../constants/Constants'
import { fetchUserData } from '../../api/API'

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 150,
  },
}))

export default function UsersDropDownList({ label, fullWidth, variant = 'standard', ...props }) {
  const classes = useStyles()
  const { data = [] } = useQuery('users', fetchUserData)
  const userAll = { UserLogin: 'Visi lietotāji', UserId: ALL_USERS_ID }
  const newUsersList = [userAll, ...data]
  return (
    <FormControl className={classes.select} fullWidth={fullWidth} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select {...props} label={label}>
        {newUsersList.map(({ UserId, UserLogin, UserLastName, UserName }) => (
          <MenuItem value={UserId}>
            {UserLogin}
            {UserId !== -1 ? (
              <>
                ({UserName} {UserLastName})
              </>
            ) : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
