import React from "react";
import { useQuery } from "react-query";
import {
    fetchPrivilegesData,
} from "../../../api/API";
import moment from "moment";
import { addDashToPersonCode, normalizePersonCode } from "../../../utils/Misc";
import { PERSON_CODE_LENGTH } from "../../../constants/Constants";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";
import { centsToEuros, formatNumber } from "../../../utils/Number";

export default function Privileges({ personCode, selectedPeriod }) {
    const [rows, setRows] = React.useState([]);
    const columnsALDIS = [
        {
            title: "Līguma Nr.",
            field: "ContractNumber",
            cellStyle: { whiteSpace: "nowrap" },
        },
        {
            title: "Personas kods",
            field: "PersonCode",
            render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
        },
        {
            title: "Vārds",
            field: "PersonFirstName",
            render: ({ PersonFirstName, PersonOtherNames }) =>
                [PersonFirstName, PersonOtherNames].join(" "),
        },
        { title: "Uzvārds", field: "PersonLastName" },
        { title: "Kategorija", field: "DiscountText" },
        { title: "Pakalpojuma sniedzējs", field: "ContractProviderName" },
        {
            title: "Atbalsta apmērs (EUR)",
            field: "DiscountAmount",
            render: ({ DiscountAmount }) => formatNumber(centsToEuros(DiscountAmount)),
        },
        { title: "Komentārs", field: "AddReason" },
    ];

    const { isLoading } = useQuery(
        [
            "privileges-data",
            {
                personCode,
                selectedPeriod,
            },
        ],
        (_, { personCode, selectedPeriod }) => {
            const normalizedPersonCode = normalizePersonCode(personCode);
            if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return;
            return fetchPrivilegesData({
                PersonCode: normalizedPersonCode,
                DataOrigin: "PORTAL",
                DiscountStatusFrom: moment(selectedPeriod).format("DD.MM.YYYY"),
                DiscountStatusTill: moment(selectedPeriod)
                    .endOf("month")
                    .format("DD.MM.YYYY"),
            });
        },
        {
            onSuccess: (data) => {
                if (data) setRows(data);
                else setRows([]);
            },
            onError: (error) => {
                console.error(error);
                setRows([]);
            },
        }
    );

    return (
        <CustomizedMaterialTable
            columns={columnsALDIS}
            data={rows}
            isLoading={isLoading}
            options={{
                defaultExpanded: true,
            }}
        />
    );
}