import { useState } from 'react'
import {
  Typography,
  makeStyles,
  Box,
  TextField,
  FormControl,
  Button,
  TableRow,
  TableCell,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FileExportIcon from 'mdi-material-ui/FileExport'
import { MTableBody } from 'material-table'
import moment from 'moment'
import { useQuery } from 'react-query'

import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import { ALL_PROVIDERS_ID } from '../../../constants/Constants'
import ProvidersDropDownList from '../../elements/ProvidersDropDownList'
import ResetButton from '../../elements/ResetButton'
import DatePicker from '../../elements/DatePicker'
import { alBalanceColumns } from './columns'
import { fetchOverviewsMonthlyData } from '../../../api/API'
import { xlsxExporter, sortOverviewDataByDate } from '../../../utils/utils'
import { centsToEuros, formatNumber, formatFloat } from '../../../utils/Number'
import { normalizePersonCode } from '../../../utils/Misc'

const useStyles = makeStyles((theme) => ({
  overviewTitle: {
    color: theme.palette.primary.main,
  },
  formControl: {
    minWidth: 150,
    marginRight: 16,
  },
  search: {
    minWidth: 150,
  },
  fixedWidth: {
    width: 150,
    marginRight: 16,
  },
  resetButton: {
    margin: '0 8px',
  },
  searchButton: {
    marginTop: '8px',
  },
  tableCell: {
    fontWeight: '700',
    padding: '6px 16px',
  },
}))

export const ALBalance = () => {
  const classes = useStyles()
  const [alBalanceTableData, setAlBalanceTableData] = useState([])
  const [calculationData, setCalculationData] = useState({})
  const [hasYearDateError, setHasYearDateError] = useState(false)
  const [provider, setProvider] = useState(ALL_PROVIDERS_ID)
  const currentYear = moment().startOf('year').format('YYYY')
  const [yearFrom, setYearFrom] = useState(currentYear)
  const [personCode, setPersonCode] = useState('')

  const { refetch, isFetching } = useQuery(
    'al-support',
    () => {
      const reportFrom = yearFrom
        ? moment(yearFrom).startOf('year').format('DD.MM.YYYY')
        : moment().startOf('year').subtract(2, 'years').format('DD.MM.YYYY')
      const reportTill = yearFrom
        ? moment(yearFrom).endOf('year').format('DD.MM.YYYY')
        : moment().endOf('year').format('DD.MM.YYYY')

      const normalizedPersonCode = normalizePersonCode(personCode)

      return fetchOverviewsMonthlyData({
        ProviderId: provider === ALL_PROVIDERS_ID ? null : provider,
        PersonCode: normalizedPersonCode,
        ReportFrom: reportFrom,
        ReportTill: reportTill,
      })
    },
    {
      onSuccess: (data) => {
        const { MonthList: alBalanceTableData, ...calculationData } = data
        const formattedData = sortOverviewDataByDate(alBalanceTableData || [], 'ReportMonth')
        setAlBalanceTableData(formattedData)

        const formttedCalculationData = {
          DiscountAmount: formatNumber(centsToEuros(calculationData.DiscountAmount)),
          PayoutAmount: formatNumber(centsToEuros(calculationData.PayoutAmount)),
          RemainingAmount: formatNumber(centsToEuros(calculationData.RemainingAmount)),
        }
        setCalculationData(formttedCalculationData)
      },
      onError: () => {
        setAlBalanceTableData([])
        setCalculationData([])
      },
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  const resetFilters = () => {
    setProvider(ALL_PROVIDERS_ID)
    setPersonCode('')
    setYearFrom(currentYear)
    setAlBalanceTableData([])
    setCalculationData([])
  }

  const exportTable = () => {
    const columnTitles = alBalanceColumns.map((column) => ({ title: column.title }))
    const data = [columnTitles.map((column) => `${column.title}`)]
    alBalanceTableData?.forEach(
      ({
        ProviderName,
        ReportMonth,
        DiscountContract,
        DiscountAmount,
        PayoutContract,
        PayoutAmount,
      }) => {
        const formattedLine = [
          ProviderName,
          moment(new Date(ReportMonth)).format('MM.YYYY'),
          DiscountContract,
          formatFloat(centsToEuros(DiscountAmount)),
          PayoutContract,
          formatFloat(centsToEuros(PayoutAmount)),
        ]
        data.push(formattedLine)
      }
    )

    if (alBalanceTableData?.length > 0) {
      /**
       * Helper constant to push
       * "calculationData" to last 2 columns
       * */
      const calculationDataIndention = ['', '', '', '']

      data.push([
        ...calculationDataIndention,
        'Kopā piešķirtais, EUR',
        parseFloat(calculationData.DiscountAmount),
      ])
      data.push([
        ...calculationDataIndention,
        'Kopā izmantotais, EUR',
        parseFloat(calculationData.PayoutAmount),
      ])
      data.push([
        ...calculationDataIndention,
        'Atlikums, EUR',
        parseFloat(calculationData.RemainingAmount),
      ])
    }

    const fileNameDate = yearFrom
      ? `${moment(yearFrom).startOf('year').format('MM.YYYY')} - ${moment(yearFrom)
          .endOf('year')
          .format('MM.YYYY')}`
      : alBalanceTableData.length > 0
      ? `${moment(new Date(alBalanceTableData[0].ReportMonth)).format('MM.YYYY')} - ${moment(
          new Date(alBalanceTableData[alBalanceTableData.length - 1].ReportMonth)
        ).format('MM.YYYY')}`
      : ''
    const fileName = `AL_detalizēta_bilance (${fileNameDate})`

    xlsxExporter(data, fileName)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.overviewTitle} variant="h6">
        AL detalizēta bilance
      </Typography>
      <Box display="flex" flexWrap="wrap" margin="16px 0">
        <ProvidersDropDownList
          className={classes.formControl}
          margin="none"
          label="Tirgotājs"
          value={provider}
          onChange={({ target: { value } }) => setProvider(value)}
        />
        <DatePicker
          className={classes.fixedWidth}
          value={yearFrom}
          label="Gads"
          views={['year']}
          inputVariant="standard"
          margin="none"
          format="YYYY"
          onChange={(date) => {
            setYearFrom(date)
          }}
          onError={(err) => setHasYearDateError(!!err)}
        />
        <FormControl className={classes.formControl}>
          <TextField
            variant="standard"
            label="Personas kods"
            className={classes.search}
            value={personCode}
            onChange={({ target: { value } }) => setPersonCode(value)}
          />
        </FormControl>
        <Box display="flex" alignItems="center">
          <Button
            onClick={() => {
              if (hasYearDateError) {
                return
              }

              refetch()
            }}
            color="primary"
            variant="contained"
            className={classes.searchButton}
            startIcon={<SearchIcon />}
          >
            Meklēt
          </Button>
          <ResetButton className={classes.resetButton} onClick={resetFilters} />
        </Box>
        <Box mt={2} ml="auto">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileExportIcon />}
            onClick={exportTable}
          >
            Eksports
          </Button>
        </Box>
      </Box>
      <CustomizedMaterialTable
        columns={alBalanceColumns}
        data={alBalanceTableData || []}
        options={{
          defaultExpanded: true,
        }}
        isLoading={isFetching}
        components={{
          Body: (props) => (
            <>
              <MTableBody {...props} />
              {alBalanceTableData?.length > 0 && (
                <>
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell className={classes.tableCell}>Kopā piešķirtais, EUR</TableCell>
                    <TableCell className={classes.tableCell}>
                      {calculationData.DiscountAmount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell className={classes.tableCell}>Kopā izmantotais, EUR</TableCell>
                    <TableCell className={classes.tableCell}>
                      {calculationData.PayoutAmount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell className={classes.tableCell}>Atlikums, EUR</TableCell>
                    <TableCell className={classes.tableCell}>
                      {calculationData.RemainingAmount}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </>
          ),
        }}
      />
    </Box>
  )
}
