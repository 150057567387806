import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CustomizedMaterialTable from '../elements/CustomizedMaterialTable'
import DatePicker from '../elements/DatePicker'
import ProvidersDropDownList from '../elements/ProvidersDropDownList'
import { useQuery } from 'react-query'
import {
  fetchContractProviderData,
  fetchProviderAwardedDiscountData,
  fetchProviderDiscountData,
} from '../../api/API'
import moment from 'moment'
import { centsToEuros, formatNumber } from '../../utils/Number'
import ResetButton from '../elements/ResetButton'
import { addDashToPersonCode, normalizePersonCode } from '../../utils/Misc'
import { PERSON_CODE_LENGTH } from '../../constants/Constants'
import { ALL_PROVIDERS_ID } from '../../constants/Constants'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  formControl: {
    minWidth: 150,
    marginRight: 16,
  },
  search: {
    minWidth: 150,
  },
  fixedWidth: {
    width: 150,
  },
  resetButton: {
    margin: '0 8px',
  },
  searchButton: {
    marginTop: '8px',
  },
})

const contractColumns = [
  { title: 'Līguma Nr.', field: 'ContractNumber' },
  {
    title: 'Līguma spēkā stāšanās datums',
    field: 'ContractEffectFrom',
    customSort: (a, b) =>
      moment(a.ContractEffectFrom, 'DD.MM.YYYY').valueOf() -
      moment(b.ContractEffectFrom, 'DD.MM.YYYY').valueOf(),
  },
  {
    title: 'Līguma beigu datums',
    field: 'ContractEffectTill',
  },
  { title: 'Tirgotājs', field: 'ProviderName' },
  {
    title: 'Līgumslēdzēja personas kods vai reģ. Nr.',
    field: 'ContractorCode',
  },
  {
    title: 'Līgumslēdzēja uzvārds vai nosaukums',
    field: 'ContractorLastName',
  },
  {
    title: 'Līgumslēdzēja vārds',
    field: 'ContractorFirstName',
    render: ({ ContractorFirstName, ContractorOtherNames }) =>
      [ContractorFirstName, ContractorOtherNames].join(' '),
  },
  {
    title: 'Vārds',
    field: 'PersonFirstName',
    render: ({ PersonFirstName, PersonOtherNames }) =>
      [PersonFirstName, PersonOtherNames].join(' '),
  },
  { title: 'Uzvārds', field: 'PersonLastName' },
  {
    title: 'AL vai potenciālā AL personas kods',
    field: 'PersonCode',
    render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
  },
]

const alisContractColumns = [
  {
    title: 'Līguma Nr.',
    field: 'ContractNr',
    cellStyle: { whiteSpace: 'nowrap' },
  },
  {
    title: 'Periods',
    field: 'DiscountPeriod',
    customSort: (a, b) =>
      moment(a.DiscountPeriod, 'DD.MM.YYYY').valueOf() -
      moment(b.DiscountPeriod, 'DD.MM.YYYY').valueOf(),
  },
  { title: 'Personas kods', field: 'PersonCode' },
  {
    title: 'Vārds',
    field: 'PersonFirstName',
    render: ({ PersonFirstName, PersonOtherNames }) =>
      [PersonFirstName, PersonOtherNames].join(' '),
  },
  { title: 'Uzvārds', field: 'PersonLastName' },
  { title: 'Adrese', field: 'ObjectAddressText' },
  { title: 'Kategorijā', field: 'DiscountText' },
  { title: 'Tirgotājs', field: 'ProviderName' },
  {
    title: 'Atbalsta apmērs (EUR)',
    field: 'DiscountAmount',
    render: ({ DiscountAmount }) => formatNumber(centsToEuros(DiscountAmount)),
  },
]

const awardedDiscountContractColumns = [
  { title: 'Līguma Nr.', field: 'ContractNr' },
  {
    title: 'Periods',
    field: 'PayoutPeriod',
    customSort: (a, b) =>
      moment(a.PayoutPeriod, 'DD.MM.YYYY').valueOf() -
      moment(b.PayoutPeriod, 'DD.MM.YYYY').valueOf(),
  },
  { title: 'Personas kods', field: 'PersonCode' },
  {
    title: 'Vārds',
    field: 'PersonFirstName',
    render: ({ PersonFirstName, PersonOtherNames }) =>
      [PersonFirstName, PersonOtherNames].join(' '),
  },
  { title: 'Uzvārds', field: 'PersonLastName' },
  { title: 'Tirgotājs', field: 'ProviderName' },
  {
    title: 'Atbalsta apmērs (EUR)',
    field: 'PayoutAmount',
    render: ({ PayoutAmount }) => formatNumber(centsToEuros(PayoutAmount)),
  },
]

const CONTRACT_TYPES = {
  CONTRACTS: 'Līgumi',
  ALDIS_CONTRACTS: 'Tirgotājam nosūtītie dati',
  AWARDED_DISCOUNT_CONTRACTS: 'Tirgotāju piemērotais atbalsts',
}

export default function Contracts() {
  const classes = useStyles()
  const [rows, setRows] = React.useState([])
  const [personCode, setPersonCode] = React.useState('')
  const [contractNumber, setContractNumber] = React.useState('')
  const [selectedProvider, setSelectedProvider] = React.useState(ALL_PROVIDERS_ID)
  const startingMonth = moment().startOf('month')
  const [selectedPeriod, setSelectedPeriod] = React.useState(startingMonth)
  const [selectedContractType, setSelectedContractType] = React.useState(CONTRACT_TYPES.CONTRACTS)

  const { isLoading, refetch } = useQuery(
    [
      'contract-data',
      {
        personCode,
        contractNumber,
        selectedPeriod,
        selectedProvider,
      },
    ],
    (_, { personCode, contractNumber, selectedPeriod, selectedProvider }) => {
      const normalizedPersonCode = normalizePersonCode(personCode)
      if (normalizedPersonCode.length !== PERSON_CODE_LENGTH && !contractNumber) return

      const commonPayload = {
        ProviderId: selectedProvider === ALL_PROVIDERS_ID ? null : selectedProvider,
        PersonCode: normalizedPersonCode || null,
      }

      switch (selectedContractType) {
        case CONTRACT_TYPES.CONTRACTS: {
          return fetchContractProviderData({
            ...commonPayload,
            ContractNumber: contractNumber || null,
            ContractEffectFrom: moment(selectedPeriod).format('DD.MM.YYYY'),
            ContractEffectTill: moment(selectedPeriod).endOf('month').format('DD.MM.YYYY'),
          })
        }

        case CONTRACT_TYPES.AWARDED_DISCOUNT_CONTRACTS: {
          return fetchProviderDiscountData({
            ...commonPayload,
            ContractNr: contractNumber || null,
            PayoutPeriodFrom: moment(selectedPeriod).format('DD.MM.YYYY'),
            PayoutPeriodTill: moment(selectedPeriod).endOf('month').format('DD.MM.YYYY'),
          })
        }

        case CONTRACT_TYPES.ALDIS_CONTRACTS: {
          return fetchProviderAwardedDiscountData({
            ...commonPayload,
            ContractNumber: contractNumber || null,
            DiscountPeriodFrom: moment(selectedPeriod).format('DD.MM.YYYY'),
            DiscountPeriodTill: moment(selectedPeriod).endOf('month').format('DD.MM.YYYY'),
          })
        }
        default: {
          return
        }
      }
    },
    {
      onSuccess: (data) => {
        if (data) setRows(data)
        else setRows([])
      },
      onError: () => setRows([]),
      enabled: false,
    }
  )

  const resetFilters = () => {
    setRows([])
    setPersonCode('')
    setContractNumber('')
    setSelectedProvider(ALL_PROVIDERS_ID)
    setSelectedPeriod(startingMonth)
    setSelectedContractType(CONTRACT_TYPES.CONTRACTS)
  }

  return (
    <>
      <Box display="flex" flexWrap="wrap" margin="16px 0">
        <FormControl className={classes.formControl}>
          <InputLabel>Saraksts</InputLabel>
          <Select
            value={selectedContractType}
            onChange={({ target: { value } }) => {
              setRows([])
              setSelectedContractType(value)
            }}
          >
            {Object.values(CONTRACT_TYPES).map((t) => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ProvidersDropDownList
          className={classes.formControl}
          margin="none"
          label="Tirgotājs"
          value={selectedProvider}
          onChange={({ target: { value } }) => setSelectedProvider(value)}
        />
        <Box mr={2}>
          <DatePicker
            className={classes.fixedWidth}
            views={['year', 'month']}
            openTo="year"
            format="MMMM YYYY"
            InputProps={{ readOnly: true }}
            value={selectedPeriod}
            inputVariant="standard"
            label="Periods"
            margin="none"
            maxDate={moment()}
            onChange={(date) => setSelectedPeriod(date)}
            openOnInputClick
          />
        </Box>

        <FormControl className={classes.formControl}>
          <TextField
            variant="standard"
            label="Personas kods"
            className={classes.search}
            value={personCode}
            onChange={({ target: { value } }) => setPersonCode(value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            variant="standard"
            label="Līguma numurs"
            className={classes.search}
            value={contractNumber}
            onChange={({ target: { value } }) => setContractNumber(value)}
          />
        </FormControl>
        <Box display="flex" alignItems="center">
          <Button
            onClick={refetch}
            color="primary"
            variant="contained"
            className={classes.searchButton}
            startIcon={<SearchIcon />}
          >
            Meklēt
          </Button>
          <ResetButton className={classes.resetButton} onClick={resetFilters} />
        </Box>
      </Box>
      {selectedContractType === CONTRACT_TYPES.CONTRACTS && (
        <CustomizedMaterialTable
          columns={contractColumns}
          data={rows}
          isLoading={isLoading}
          options={{
            defaultExpanded: true,
          }}
        />
      )}
      {selectedContractType === CONTRACT_TYPES.AWARDED_DISCOUNT_CONTRACTS && (
        <CustomizedMaterialTable
          columns={awardedDiscountContractColumns}
          data={rows}
          isLoading={isLoading}
          options={{
            defaultExpanded: true,
          }}
        />
      )}
      {selectedContractType === CONTRACT_TYPES.ALDIS_CONTRACTS && (
        <CustomizedMaterialTable
          columns={alisContractColumns}
          data={rows}
          isLoading={isLoading}
          options={{
            defaultExpanded: true,
          }}
        />
      )}
    </>
  )
}
