import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSnackBar } from "../../providers/SnackBarProvider";
import DiscountTypesDropDownList from "../../elements/DiscountTypesDropDownList";
import { deletePersonStatus } from "../../../api/API";
import { useMutation } from "react-query";
import { confirmDialog } from "../../elements/ConfirmDialog";
import { defaultErrMsg } from "../../../constants/Constants";

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  dialogTitle: {
    paddingTop: 4,
    textTransform: "uppercase",
  },
  button: {
    minWidth: 130,
  },
});

export default function ModalDeleteStatus({ person, onClose, onSuccess }) {
  const classes = useStyles();
  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Dzēst aizsargāta lietotāja statusu
            </Box>
          </DialogTitle>
          <Form person={person} onSuccess={onSuccess} onClose={onClose} />
        </Box>
      </Box>
    </Dialog>
  );
}

function Form({ person, onSuccess, onClose }) {
  const classes = useStyles();
  const { showSnackBar } = useSnackBar();
  //form data
  const [formState, setFormState] = React.useState({
    personCode: person.PersonCode,
    firstName: person.PersonFirstName,
    lastName: person.PersonLastName,
    categories: person.Discounts
      ? Object.entries(person.Discounts).map(
          ([DiscountTypeId, DiscountTypeDesc]) => ({
            DiscountTypeId: Number(DiscountTypeId),
            DiscountTypeDesc,
          })
        )
      : [],
    comment: "",
  });

  //form errors
  const [formErrors, setFormErrors] = React.useState({});
  const [deletePersonStatusMutation] = useMutation(
    ({ PersonCode, ProviderId, StatusCodeList, DeleteReason }) => {
      return deletePersonStatus({
        PersonCode,
        ProviderId,
        StatusCodeList,
        DeleteReason,
      });
    },
    {
      onSuccess: () => {
        showSnackBar();
        onSuccess();
        onClose();
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        }),
    }
  );
  const onFieldChange = ({ target }) =>
    setFormState({ ...formState, [target.name]: target.value });

  const onFieldChangeAutoComplete = (field, value) =>
    setFormState({ ...formState, [field]: value });

  const isFormValid = () => {
    const { personCode, categories, comment } = formState;
    const _errors = [];
    if (!personCode) _errors["personCode"] = defaultErrMsg;
    if (!comment) _errors["comment"] = defaultErrMsg;
    if (categories.length < 1) _errors["categories"] = defaultErrMsg;

    setFormErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const onSubmit = async () => {
    if (!isFormValid()) return;
    const confirm = await confirmDialog({
      text: "Vai tiešām dzēst aizsargātā lietotāja statusu?",
    });
    if (confirm.ok) {
      deletePersonStatusMutation({
        DeleteReason: formState.comment,
        PersonCode: person.PersonCode,
        ProviderId: person.ProviderIdList[0], // TODO: there should be one provider id (not list) attached to persons
        StatusCodeList: formState.categories.map(({ DiscountTypeId }) => ({
          ProtectedUserStatusCode: DiscountTypeId,
        })),
      });
    }
  };

  return (
    <>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="dense"
          label="Personas kods"
          fullWidth
          value={formState.personCode}
          disabled
        />

        <TextField
          variant="outlined"
          margin="dense"
          label="Vārds"
          fullWidth
          value={formState.firstName}
          disabled
        />

        <TextField
          variant="outlined"
          margin="dense"
          label="Uzvārds"
          fullWidth
          value={formState.lastName}
          disabled
        />

        <DiscountTypesDropDownList
          noFetch
          fullWidth
          label="Kategorija"
          margin="dense"
          multiSelect
          value={formState.categories}
          onChange={(e, newValue) =>
            onFieldChangeAutoComplete("categories", newValue)
          }
          helperText={formErrors.categories}
          error={Boolean(formErrors.categories)}
        />

        <TextField
          variant="outlined"
          margin="dense"
          multiline
          rows={2}
          label="Iemesls"
          name="comment"
          autoComplete="off"
          fullWidth
          onChange={onFieldChange}
          value={formState.comment}
          helperText={formErrors.comment}
          error={Boolean(formErrors.comment)}
        />
      </DialogContent>
      <DialogActions>
        <Box px={2}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={onSubmit}
            color="secondary"
          >
            Dzēst lietotāja statusu
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
