import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";
import { useQuery } from "react-query";
import { fetchExchangeData } from "../../../api/API";

const columns = [
  {
    field: "ActionDate",
    title: "Datums, laiks",
  },
  { field: "DataSourceDesc", title: "Avots" },
  {
    field: "ExchangeTypeName",
    title: "Darbības",
    cellStyle: {
      minWidth: 200,
    },
  },
];

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  dialogTitle: {
    paddingTop: 4,
    textTransform: 'uppercase',
  },
});

export default function ModalExchanges({ personCode, onClose }) {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const { isLoading } = useQuery(
    [
      "exchange-data",
      {
        personCode,
      },
    ],
    (_, { personCode }) => {
      if (!personCode) return;
      return fetchExchangeData({
        PersonCode: personCode,
      });
    },
    {
      onSuccess: (data) => {
        if (data) setRows(data);
        else setRows([]);
      },
      onError: (error) => {
        console.error(error);
        setRows([]);
      },
    }
  );

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon
            className={classes.closeIcon}
            color="primary"
            onClick={onClose}
          />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Aizsargātā lietotāja izmaiņu vēsture
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomizedMaterialTable
              key={personCode} 
              columns={columns}
              data={rows}
              isLoading={isLoading}
            />
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
}
