import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import Tooltip from '@material-ui/core/Tooltip'

export default function ResetButton(props) {
  return (
    <Tooltip title="Atiestatīt filtrus">
      <IconButton {...props} style={{ marginTop: 8 }}>
        <AutorenewIcon color="primary" />
      </IconButton>
    </Tooltip>
  )
}
