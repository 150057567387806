import React from "react";
import { ReactQueryConfigProvider as Provider } from "react-query";

const overrides = {
  queries: {
    refetchOnWindowFocus: false,
    cacheTime: 0, // to disable cache
    retry: 0,
  },
};

export default function ReactQueryConfigProvider({ children }) {
  return <Provider config={overrides}>{children}</Provider>;
}
