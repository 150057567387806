import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import HomeIcon from '@material-ui/icons/Home'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory, useLocation } from 'react-router-dom'

import { OverviewMenu } from './OverviewMenu'

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.background.paper,
  },
  homeIcon: {
    width: 34,
    height: 34,
  },
  overviewButton: {
    width: 'auto',
    color: '#FFF',
    padding: '6px 12px',
    borderRadius: 0,
    opacity: ({ isOverviewRoute, isDropdownMenuOpen }) =>
      isDropdownMenuOpen || isOverviewRoute ? '1' : '0.7',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      display: ({ isOverviewRoute }) => (isOverviewRoute ? 'block' : 'none'),
      height: '2px',
      minWidth: '100px',
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  expandIcon: {
    marginBottom: '2px',
    transition: '0.4s',
    transform: ({ isDropdownMenuOpen }) => isDropdownMenuOpen && 'rotate(180deg)',
  },
  navigationBar: {
    minWidth: 'auto',
    '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
    }
  },
  homeNavigation: {
    minWidth: '100px'
  }
}))

export default function Navigation() {
  const location = useLocation()
  const isOverviewRoute = location.pathname.startsWith('/pārskati')
  const [dropdownMenuContainer, setDropdownMenuContainer] = useState(null)
  const classes = useStyles({ isOverviewRoute, isDropdownMenuOpen: Boolean(dropdownMenuContainer) })
  const history = useHistory()
  const isAdmin = localStorage.getItem('isAdmin') === 'true'

  return (
    <Box alignItems="center" display="flex" mb={2}>
      <AppBar position="static" color="primary">
        <Tabs
          value={location.pathname}
          onChange={(_, selectedPath) => history.push(selectedPath)}
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            indicator: classes.indicator,
          }}
          TabIndicatorProps={{
            style: { 
              transition: 'none' },
          }}
        >
          <Tab icon={<HomeIcon className={classes.homeIcon} />} value="/" className={classes.homeNavigation} />
          <Tab label="Reģistri" value="/registri" className={classes.navigationBar} />
          <Tab label="Tirgotāji" value="/tirgotāji" className={classes.navigationBar} />
          <Tab label="Klasifikatori" value="/klasifikatori" className={classes.navigationBar} />
          <Tab label="Monitorings" value="/monitorings" className={classes.navigationBar} />
          <Tab label="Datu atskaite" value="/datu-atskaite" className={classes.navigationBar} />
          <Button
            className={classes.overviewButton}
            onClick={(e) => setDropdownMenuContainer(e.currentTarget)}
          >
            Pārskati
            <ExpandMoreIcon className={classes.expandIcon} />
          </Button>
          <Tab label="Dzēstie lietotāji" value="/dzēstie-lietotāji" className={classes.navigationBar} />
          {isAdmin && <Tab label="Lietotāju pārvaldība" value="/lietotāju-pārvaldība" className={classes.navigationBar} />}
        </Tabs>
      </AppBar>
      <OverviewMenu
        dropdownMenuContainer={dropdownMenuContainer}
        onClose={() => setDropdownMenuContainer(null)}
      />
    </Box>
  )
}
