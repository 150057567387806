import React, { useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { useQuery } from 'react-query'
import { fetchProviderTypes } from '../../api/API'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ALL_PROVIDERS_ID } from '../../constants/Constants'

export default function ProvidersDropDownList({
  label,
  helperText,
  error,
  fullWidth,
  variant = 'standard',
  margin = 'normal',
  className,
  selectFirst,
  name,
  size = 'medium',
  disabled,
  ...props
}) {
  const [providerTypesData, setProviderTypesData] = useState([])

  useQuery('provider-types', fetchProviderTypes, {
    onSuccess: (data) => {
      const sortedProviderTypes = data
        .slice()
        .sort((a, b) =>
          a.ProviderName > b.ProviderName ? 1 : b.ProviderName > a.ProviderName ? -1 : 0
        )
      const providersAll = [
        { ProviderId: ALL_PROVIDERS_ID, ProviderName: 'Visi tirgotāji' },
        ...sortedProviderTypes,
      ]
      setProviderTypesData(providersAll)

      if (selectFirst && sortedProviderTypes?.length > 0) {
        props.onChange({ target: { value: sortedProviderTypes[0].ProviderId, name: name } })
      }
    },
  })

  return (
    <FormControl
      size={size}
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      margin={margin}
      error={error}
    >
      <InputLabel id="provider-select-label">{label}</InputLabel>
      <Select
        labelId="provider-select-label"
        name={name}
        label={label}
        disabled={disabled}
        {...(disabled && { IconComponent: () => null })}
        {...props}
      >
        {providerTypesData.map(({ ProviderId, ProviderName }) => (
          <MenuItem key={ProviderId} value={ProviderId}>
            {ProviderName}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
