import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'

import { useSnackBar } from '../../providers/SnackBarProvider'
import ProvidersDropDownList from '../../elements/ProvidersDropDownList'
import AgreementDropDownList from '../../elements/AgreementDropDownList'
import { getContractData, setContractNumber } from '../../../api/API'
import { defaultErrMsg } from '../../../constants/Constants'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 4,
    textTransform: 'uppercase',
  },
  button: {
    minWidth: 130,
    marginTop: 0,
  },
  manualInputButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  manualInputButton: {
    textTransform: 'none',
  },
  manualInputField: {
    marginBottom: '8px',
  },
})

export default function ModalAgreement({ onClose, person, onSuccess }) {
  const classes = useStyles()
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon className={classes.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Labot līgumu piešķirtajam atbalstam
            </Box>
          </DialogTitle>
          <Form person={person} onClose={onClose} refetchHistoryData={onSuccess} />
        </Box>
      </Box>
    </Dialog>
  )
}

const Form = ({ person, onClose, refetchHistoryData }) => {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [formErrors, setFormErrors] = useState({})
  const [isContractManualInput, setIsContractManualInput] = useState(false)
  const [providerContracts, setProviderContracts] = useState([])
  const [formState, setFormState] = useState({
    selectedPerson: person,
    contractNumberList: '',
    contractNumberManual: '',
  })
  const [activeContract, setActiveContract] = useState({
    selectedPerson: person,
    contractNumber: '',
    providerId: '',
  })

  const onFieldChange = ({ target }) => {
    setFormState({ ...formState, [target.name]: target.value })

    setFormErrors({ ...formErrors, [target.name]: '' })
  }

  useQuery(
    [
      'contract-by-person-code',
      {
        person,
      },
    ],
    (_, { person }) => {
      return getContractData({
        PersonCode: person.PersonCode,
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          setProviderContracts(data.ContractsList || [])
          setActiveContract({
            ...activeContract,
            contractNumber: data.ContractNr,
            providerId: data.ProviderId,
          })
        }
      },
    }
  )

  const [setContractNumberMutation] = useMutation(
    ({ PersonCode, ContractNumber }) => {
      return setContractNumber({
        PersonCode,
        ContractNumber,
      })
    },
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
        refetchHistoryData()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        })
      },
    }
  )

  const isFormValid = () => {
    const { contractNumberList, contractNumberManual } = formState
    const _errors = []

    if (isContractManualInput || providerContracts.length === 0) {
      if (!contractNumberManual) _errors['contractNumberManual'] = defaultErrMsg
      if (contractNumberManual && contractNumberManual.length < 8)
        _errors['contractNumberManual'] = 'Līgumam jābūt vismaz 8 simbolus garam'
    } else {
      if (!contractNumberList) _errors['contractNumberList'] = defaultErrMsg
    }

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return

    setContractNumberMutation({
      PersonCode: formState.selectedPerson.PersonCode,
      ContractNumber:
        isContractManualInput || providerContracts.length === 0
          ? formState.contractNumberManual
          : formState.contractNumberList,
    })
  }

  return (
    <>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Līguma Nr."
          value={activeContract.contractNumber}
          margin="dense"
          fullWidth
          disabled
        />
        <ProvidersDropDownList
          variant="outlined"
          margin="dense"
          label="Pakalpojuma sniedzējs"
          value={activeContract.providerId}
          fullWidth
          disabled
        />
        {providerContracts.length > 0 ? (
          <Box>
            {isContractManualInput ? (
              <TextField
                variant="outlined"
                label="Līguma Nr."
                name="contractNumberManual"
                value={formState.contractNumberManual}
                onChange={onFieldChange}
                className={classes.manualInputField}
                margin="dense"
                helperText={formErrors.contractNumberManual}
                error={Boolean(formErrors.contractNumberManual)}
                fullWidth
              />
            ) : (
              <AgreementDropDownList
                providerContracts={providerContracts}
                variant="outlined"
                margin="dense"
                label="Līguma Nr."
                name="contractNumberList"
                value={formState.contractNumberList}
                onChange={onFieldChange}
                error={Boolean(formErrors.contractNumberList)}
                helperText={formErrors.contractNumberList}
                fullWidth
              />
            )}
            <Box className={classes.manualInputButtonBox}>
              <Button
                className={classes.manualInputButton}
                style={{ backgroundColor: 'transparent' }}
                onClick={() => setIsContractManualInput(!isContractManualInput)}
                disableRipple
              >
                <Typography color="primary">
                  {!isContractManualInput ? 'Ievadīt manuāli' : 'Izvēlēties no esošajiem'}
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : (
          <TextField
            variant="outlined"
            label="Līguma Nr."
            value={formState.contractNumberManual}
            name="contractNumberManual"
            onChange={onFieldChange}
            margin="dense"
            helperText={formErrors.contractNumberManual}
            error={Boolean(formErrors.contractNumberManual)}
            className={classes.manualInputField}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box mt={1} px={2}>
          <Button className={classes.button} variant="contained" onClick={onSubmit} color="primary">
            Saglabāt
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
