import React, { useMemo } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { useQuery } from 'react-query'
import { ALL_OPERATIONS_ID } from '../../constants/Constants'
import { fetchAuditTypes } from '../../api/API'

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 150,
  },
}))

export default function AuditTypesDropDownList({
  label = 'Darbības',
  fullWidth,
  variant = 'standard',
  ...props
}) {
  const classes = useStyles()
  const { data = [] } = useQuery('audit-types', fetchAuditTypes)
  const allOperationsData = useMemo(
    () => [{ AuditDesc: 'Visas darbības', AuditId: ALL_OPERATIONS_ID }, ...data.sort()],
    [data]
  )
  return (
    <FormControl className={classes.select} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select {...props} label={label}>
        {allOperationsData.map(({ AuditId, AuditDesc }) => (
          <MenuItem key={AuditId} value={AuditId}>
            {AuditDesc}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
