import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import { useMutation, useQuery } from 'react-query'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import moment from 'moment'

import CustomizedMaterialTable from '../elements/CustomizedMaterialTable'
import { fetchMonitoringData, setMonitoringStatusProcess } from '../../api/API'
import DatePicker from '../elements/DatePicker'
import MonitoringStatusesDropDownList from '../elements/MonitoringStatusesDropDownList'
import ResetButton from '../elements/ResetButton'

const columns = [
  { field: 'TypeName', title: 'Nosaukums' },
  {
    field: 'PlannedDate',
    title: 'Datums, laiks (līdz kuram jāveic datu apmaiņa)',
    customSort: (a, b) =>
      moment(a.PlannedDate, 'DD.MM.YYYY HH:mm:s').valueOf() -
      moment(b.PlannedDate, 'DD.MM.YYYY HH:mm:s').valueOf(),
  },
  {
    field: 'EventDate',
    title: 'Datums, laiks (kad veikta datu apmaiņa)',
  },
  { field: 'StatusDesc', title: 'Datu apmaiņas statuss' },
]

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        width: '100%',
      },
    },
  },
  search: {
    minWidth: 150,
  },
  fixedWidth: {
    width: 150,
  },
  monitoringStateButton: {
    marginTop: '8px',
    minWidth: '140px',
  },
  monitoringState: {
    margin: '0 8px',
  },
}))

export default function Monitoring() {
  const classes = useStyles()
  const [monitoringStopStatus, setMonitoringStopStatus] = React.useState(false)
  const [stopReason, setStopReason] = React.useState('')
  const [name, setName] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [status, setStatus] = React.useState(null)
  const startingMonth = moment().startOf('month')
  const endingMonth = moment().endOf('month')
  const [periodFrom, setPeriodFrom] = React.useState(startingMonth)
  const [periodTo, setPeriodTo] = React.useState(endingMonth)
  const { data = [], isLoading } = useQuery(
    ['monitoring', { periodFrom, periodTo }],
    (_, { periodFrom, periodTo }) => {
      return fetchMonitoringData({
        DateFrom: periodFrom ? moment(periodFrom).format('DD.MM.YYYY') : '',
        DateTill: periodTo ? moment(periodTo).format('DD.MM.YYYY') : '',
      })
    },
    {
      onSuccess: (data) => {
        if (data) setRows(data)
        else setRows([])
      },
      onError: () => setRows([]),
    }
  )

  const { refetch: refetchMonitoringStatus } = useQuery(
    ['monitoring-status-process', { action: 'GET' }],
    (_, { action }) => {
      return setMonitoringStatusProcess({
        Action: action,
      })
    },
    {
      onSuccess: (data) => {
        setMonitoringStopStatus(data.StopStatus)
        setStopReason(data.StopReason || '')
      },
      refetchOnWindowFocus: false,
    }
  )

  const [editMonitoringStatusMutation] = useMutation(({ monitoringState }) =>
    setMonitoringStatusProcess({ Action: monitoringState })
  )

  // filter effect
  React.useEffect(() => {
    if (!data) return
    let filteredData = data.filter((d) => d.TypeName.toLowerCase().includes(name.toLowerCase()))
    if (status) filteredData = filteredData.filter((d) => Number(d.StatusId) === status)

    setRows(filteredData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, status])

  const resetFilters = () => {
    setName('')
    setPeriodFrom(startingMonth)
    setPeriodTo(endingMonth)
    setStatus(null)
  }
  return (
    <>
      <Box className={classes.searchWrapper} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <TextField
            autoFocus
            variant="standard"
            label="Nosaukums"
            className={classes.search}
            value={name}
            onChange={({ target: { value } }) => setName(value)}
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />
              ),
            }}
          />
          <Box ml={2}>
            <MonitoringStatusesDropDownList
              label="Datu apmaiņas statuss"
              value={status}
              onChange={({ target: { value } }) => setStatus(value)}
            />
          </Box>
          <Box ml={2}>
            <DatePicker
              className={classes.fixedWidth}
              views={['year', 'month']}
              openTo="year"
              format="MMMM YYYY"
              InputProps={{ readOnly: true }}
              value={periodFrom}
              inputVariant="standard"
              label="Periods no"
              margin="none"
              maxDate={moment()}
              onChange={(date) => setPeriodFrom(date)}
              openOnInputClick
            />
          </Box>
          <Box ml={2}>
            <DatePicker
              className={classes.fixedWidth}
              views={['year', 'month']}
              openTo="year"
              format="MMMM YYYY"
              InputProps={{ readOnly: true }}
              value={periodTo}
              inputVariant="standard"
              label="Periods līdz"
              margin="none"
              maxDate={moment().endOf('month')}
              onChange={(date) => setPeriodTo(date)}
              openOnInputClick
            />
          </Box>
          <ResetButton onClick={resetFilters} />
        </Box>

        <Box display="flex">
          <Typography>Datu apmaiņa: </Typography>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <Typography className={classes.monitoringState}>
                {monitoringStopStatus ? 'Apturēta' : 'Palaista'}
              </Typography>
              {stopReason ? (
                <Tooltip title={stopReason} placement="top-start">
                  <InfoIcon color="primary" />
                </Tooltip>
              ) : null}
            </Box>
            <Button
              variant="contained"
              className={classes.monitoringStateButton}
              color={monitoringStopStatus ? 'primary' : 'secondary'}
              onClick={() => {
                editMonitoringStatusMutation({
                  monitoringState: monitoringStopStatus ? 'START' : 'STOP',
                }).finally(() => {
                  refetchMonitoringStatus()
                })
              }}
            >
              {monitoringStopStatus ? 'Palaist' : 'Apturēt'}
            </Button>
          </Box>
        </Box>
      </Box>

      <CustomizedMaterialTable
        key={name.concat(status, periodFrom, periodTo)}
        columns={columns}
        data={rows}
        isLoading={isLoading}
      />
    </>
  )
}
