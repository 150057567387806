import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import LockIcon from '@material-ui/icons/Lock'
import CancelIcon from '@material-ui/icons/Cancel'
import ModalAddEditUser from './ModalAddEditUser'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import { confirmDialog } from '../../elements/ConfirmDialog'
import { useMutation, useQuery } from 'react-query'
import { fetchUserDetails, editUser, setPassword, fetchUserData } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import ResetButton from '../../elements/ResetButton'
import { REGEX_PASSWORD } from '../../../constants/Constants'

const columns = [
  { field: 'UserLogin', title: 'Lietotājvārds' },
  { field: 'UserName', title: 'Vārds' },
  { field: 'UserLastName', title: 'Uzvārds' },
]

const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  search: {
    minWidth: 150,
    marginRight: 16,
  },
}))

export default function Users() {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [openUserModal, setOpenUserModal] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [changeStatus, setChangeStatus] = React.useState(false)
  const [changeUserStatusMutation] = useMutation(
    ({ UserId, UserName, UserLastName, UserLogin, UserPass, UserActive }) =>
      editUser({
        UserId: UserId,
        UserLogin: UserLogin,
        UserPass: UserPass,
        UserName: UserName,
        UserLastName: UserLastName,
        UserActive: UserActive !== 1 ? 1 : 2,
      }),
    {
      onSuccess: () => {
        setChangeStatus(false)
        showSnackBar()
        refetch()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        })
        setChangeStatus(false)
      },
    }
  )
  const [setPasswordMutation] = useMutation((data) => setPassword(data), {
    onSuccess: () => {
      showSnackBar()
    },
    onError: (error) =>
      showSnackBar({
        severity: 'error',
        text: `kļūda: ${error.response?.data.error}`,
      }),
  })

  useQuery(
    'user-by-id',
    () => {
      return fetchUserDetails({
        UserId: selectedUser.UserId,
      })
    },
    {
      enabled: selectedUser && changeStatus,
      onSuccess: (data) => {
        if (data) {
          changeUserStatusMutation(data)
        }
      },
      onError: () => setChangeStatus(false),
    }
  )

  const { isLoading, data, refetch } = useQuery('users', fetchUserData, {
    onSuccess: (data) => {
      if (data) setRows(data)
      else setRows([])
    },
    onError: (error) => {
      console.error(error)
      setRows([])
    },
  })

  const onUserEdit = (_, user) => {
    setSelectedUser(user)
    setOpenUserModal(true)
  }

  const onPasswordEdit = async (_, user) => {
    const field = {
      label: 'Parole',
      id: 'password',
      type: 'password',
      regex: REGEX_PASSWORD,
      errorText:
        'Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam',
      infoText:
        'Parolei jāsastāv no vismaz 9 simboliem, no kuriem jābūt vismaz vienam lielajam burtam, mazajam burtam un simbolam vai ciparam',
    }
    const confirm = await confirmDialog({
      text: 'Nomainīt paroli',
      description: `Lietotājs: ${user.UserName} ${user.UserLastName}`,
      fields: [field],
    })
    if (confirm.ok) {
      const password = confirm.fields.find((f) => f.id === field.id).value
      setPasswordMutation({
        UserId: user.UserId,
        UserPass: password,
      })
    }
  }

  const onRowDelete = async (_, user) => {
    const confirm = await confirmDialog({
      text: `Vai tiešām vēlaties ${user.UserActive !== 1 ? 'aktivizēt' : 'deaktivizēt'} lietotāju?`,
      description: `Lietotājs: ${user.UserName} ${user.UserLastName}`,
    })
    if (confirm.ok) {
      setSelectedUser(user)
      setChangeStatus(true)
    }
  }

  // filter effect
  React.useEffect(() => {
    if (data) {
      setRows(
        data.filter(
          (x) =>
            x.UserLastName?.toLowerCase().includes(lastName) &&
            x.UserName?.toLowerCase().includes(firstName)
        )
      )
    }
  }, [data, firstName, lastName])

  const resetFilters = () => {
    setFirstName('')
    setLastName('')
  }
  return (
    <>
      <ModalAddEditUser
        open={openUserModal}
        onSuccess={refetch}
        user={selectedUser}
        onClose={() => setOpenUserModal(false)}
        refetch={refetch}
      />
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <div className={classes.filterWrapper}>
          <TextField
            variant="standard"
            label="Vārds"
            className={classes.search}
            value={firstName}
            onChange={({ target: { value } }) => setFirstName(value)}
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />
              ),
            }}
          />
          <TextField
            variant="standard"
            label="Uzvārds"
            className={classes.search}
            value={lastName}
            onChange={({ target: { value } }) => setLastName(value)}
            InputProps={{
              endAdornment: <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />,
            }}
          />
          <ResetButton onClick={resetFilters} />
        </div>
        <Box display="flex" flexDirection="column">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedUser(null)
              setOpenUserModal(true)
            }}
          >
            pievienot jaunu lietotāju
          </Button>
        </Box>
      </Box>
      <CustomizedMaterialTable
        key={firstName.concat(lastName)}
        columns={columns}
        data={rows}
        isLoading={isLoading}
        actions={[
          {
            icon: 'edit',
            onClick: onUserEdit,
            tooltip: 'Labot lietotāja informāciju',
          },
          {
            icon: () => <LockIcon />,
            onClick: onPasswordEdit,
            tooltip: 'Mainīt paroli',
          },
          (rowData) => {
            const isDeactivated = rowData.UserActive !== 1
            return {
              icon: () => <CancelIcon style={{ color: isDeactivated ? 'grey' : 'black' }} />,
              tooltip: isDeactivated ? 'Aktivizēt' : 'Deaktivizēt',
              onClick: onRowDelete,
            }
          },
        ]}
      />
    </>
  )
}
