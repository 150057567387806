import { useState } from 'react'
import { Typography, makeStyles, Box, FormControl, TextField, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FileExportIcon from 'mdi-material-ui/FileExport'
import { useQuery } from 'react-query'
import moment from 'moment'

import { ALL_PROVIDERS_ID } from '../../../constants/Constants'
import ProvidersDropDownList from '../../elements/ProvidersDropDownList'
import DatePicker from '../../elements/DatePicker'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import ResetButton from '../../elements/ResetButton'
import { alSupportColumns } from './columns'
import { fetchOverviewsPersonData } from '../../../api/API'
import { xlsxExporter, sortOverviewDataByDate, getDateWithCustomMonth } from '../../../utils/utils'
import { centsToEuros, formatFloat } from '../../../utils/Number'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { addDashToPersonCode, normalizePersonCode } from '../../../utils/Misc'

const useStyles = makeStyles((theme) => ({
  overviewTitle: {
    color: theme.palette.primary.main,
  },
  formControl: {
    minWidth: 150,
    marginRight: 24,
  },
  search: {
    minWidth: 150,
  },
  resetButton: {
    margin: '0 8px',
  },
  searchButton: {
    marginTop: '8px',
  },
  fixedWidth: {
    width: 150,
    marginRight: 24,
  },
}))

export const ALSupport = () => {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [alSupportTableData, setAlSupportTableData] = useState([])
  const [provider, setProvider] = useState(ALL_PROVIDERS_ID)
  const [hasYearDateError, setHasYearDateError] = useState(false)
  const [hasMonthDateError, setHasMonthDateError] = useState(false)
  const [personCode, setPersonCode] = useState('')
  const currentYear = moment().startOf('year')
  const [yearFrom, setYearFrom] = useState(currentYear)
  const [monthFrom, setMonthFrom] = useState(null)

  const resetFilters = () => {
    setProvider(ALL_PROVIDERS_ID)
    setYearFrom(currentYear)
    setMonthFrom(null)
    setPersonCode('')
    setAlSupportTableData([])
  }

  const { refetch, isFetching } = useQuery(
    'al-support',
    () => {
      let reportFrom
      let reportTill

      if (monthFrom) {
        if (!yearFrom) {
          setYearFrom(currentYear)
        }
        // Calculate date based on monthFrom and yearFrom values
        const { reportFromDate, reportTillDate } = getDateWithCustomMonth(monthFrom, yearFrom)
        reportFrom = reportFromDate
        reportTill = reportTillDate
      } else {
        reportFrom = yearFrom
          ? moment(yearFrom).startOf('year').format('DD.MM.YYYY')
          : moment().startOf('year').subtract(2, 'years').format('DD.MM.YYYY')
        reportTill = yearFrom
          ? moment(yearFrom).endOf('year').format('DD.MM.YYYY')
          : moment().endOf('year').format('DD.MM.YYYY')
      }

      const normalizedPersonCode = normalizePersonCode(personCode)

      return fetchOverviewsPersonData({
        ProviderId: provider === ALL_PROVIDERS_ID ? null : provider,
        PersonCode: normalizedPersonCode,
        ReportFrom: reportFrom,
        ReportTill: reportTill,
      })
    },
    {
      onSuccess: (data) => {
        const filterData = sortOverviewDataByDate(data, 'ReportMonth')
        setAlSupportTableData(filterData)
      },
      onError: (err) => {
        setAlSupportTableData([])
        if (err.response?.data?.error) {
          showSnackBar({
            severity: 'error',
            text: `kļūda: ${err.response.data.error}`,
          })
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  const exportTable = () => {
    const columnTitles = alSupportColumns.map((column) => ({ title: column.title }))
    const data = [columnTitles.map((column) => `${column.title}`)]
    alSupportTableData.forEach(
      ({ ProviderName, ReportMonth, PersonCode, DiscountAmount, PayoutAmount }) => {
        const formattedLine = [
          ProviderName,
          moment(new Date(ReportMonth)).format('MM.YYYY'),
          addDashToPersonCode(PersonCode),
          formatFloat(centsToEuros(DiscountAmount)),
          formatFloat(centsToEuros(PayoutAmount)),
        ]
        data.push(formattedLine)
      }
    )

    let fileNameDate

    if (monthFrom) {
      const { reportFromDate, reportTillDate } = getDateWithCustomMonth(monthFrom, yearFrom)
      fileNameDate = `${reportFromDate} - ${reportTillDate}`
    } else {
      fileNameDate = yearFrom
        ? `${moment(yearFrom).startOf('year').format('MM.YYYY')} - ${moment(yearFrom)
            .endOf('year')
            .format('MM.YYYY')}`
        : alSupportTableData.length > 0
        ? `${moment(new Date(alSupportTableData[0].ReportMonth)).format('MM.YYYY')} - ${moment(
            new Date(alSupportTableData[alSupportTableData.length - 1].ReportMonth)
          ).format('MM.YYYY')}`
        : ''
    }

    const fileName = `Piešķirtais_un_izmantotais_atbalsts_sadalījumā_pa_AL (${fileNameDate})`

    xlsxExporter(data, fileName)
  }

  return (
    <>
      <Typography className={classes.overviewTitle} variant="h6">
        Piešķirtais un izmantotais atbalsts sadalījumā pa AL
      </Typography>
      <Box display="flex" flexWrap="wrap" margin="16px 0">
        <ProvidersDropDownList
          className={classes.formControl}
          margin="none"
          label="Tirgotājs"
          value={provider}
          onChange={({ target: { value } }) => setProvider(value)}
        />
        <DatePicker
          className={classes.fixedWidth}
          value={monthFrom}
          label="Mēnesis"
          views={['month']}
          inputVariant="standard"
          margin="none"
          format="MMMM"
          onChange={(date) => {
            setMonthFrom(date)
          }}
          onError={(err) => setHasMonthDateError(!!err)}
        />
        <DatePicker
          className={classes.fixedWidth}
          value={yearFrom}
          label="Gads"
          views={['year']}
          inputVariant="standard"
          margin="none"
          format="YYYY"
          onChange={(date) => {
            setYearFrom(date)
          }}
          onError={(err) => setHasYearDateError(!!err)}
        />
        <FormControl className={classes.formControl}>
          <TextField
            variant="standard"
            label="Personas kods"
            className={classes.search}
            value={personCode}
            onChange={({ target: { value } }) => setPersonCode(value)}
          />
        </FormControl>
        <Box display="flex" alignItems="center">
          <Button
            onClick={() => {
              if (hasYearDateError || hasMonthDateError) {
                return
              }

              refetch()
            }}
            color="primary"
            variant="contained"
            className={classes.searchButton}
            startIcon={<SearchIcon />}
          >
            Meklēt
          </Button>
          <ResetButton className={classes.resetButton} onClick={resetFilters} />
        </Box>
        <Box mt={2} ml="auto">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileExportIcon />}
            onClick={exportTable}
          >
            Eksports
          </Button>
        </Box>
      </Box>
      <CustomizedMaterialTable
        columns={alSupportColumns}
        data={alSupportTableData || []}
        options={{
          defaultExpanded: true,
        }}
        isLoading={isFetching}
      />
    </>
  )
}
