import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'react-query'
import { fetchPersonDataTypesList } from '../../api/API'
import { mapFiltersToCategories, checkIsSubsetArray } from '../../utils/utils'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 150,
    maxWidth: 300,
  },
  menuItem: {
    width: '100%',
  },
  list: {
    '& ul': {
      padding: 0,
    },
  },
  checkboxFilterLabel: {
    marginLeft: 0,
    width: '100%',
  },
  checkboxCatLabel: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

export default function DataTypeDropdown({
  label,
  value,
  fullWidth,
  variant = 'standard',
  onChange,
  ...props
}) {
  const classes = useStyles()

  const { data = [] } = useQuery('data-types', fetchPersonDataTypesList)
  const categorizedData = mapFiltersToCategories(data)

  const handleCategorySelection = (checked, key) => {
    const newFilters = checked
      ? [...value, ...categorizedData[key].catFilters]
      : value.filter((filter) => !categorizedData[key].availableOptions.has(filter.DataTypeId))

    onChange(newFilters)
  }

  const handleFilterSelection = (checked, filter) => {
    const newFilters = checked
      ? [...value, filter]
      : value.filter((item) => item.DataTypeId !== filter.DataTypeId)

    onChange(newFilters)
  }

  return (
    <FormControl className={classes.select} fullWidth={fullWidth} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        MenuProps={{ classes: { paper: classes.list } }}
        label={label}
        value={value}
        renderValue={(values) => {
          const filterNames = values.map((filter) => filter.DataTypeDesc)
          return filterNames.join('; ')
        }}
        {...props}
      >
        {Object.keys(categorizedData).map((key, index) => (
          <Box key={index}>
            <Typography className={classes.checkboxCatLabel} variant="h6">
              <Checkbox
                color="default"
                onChange={(_, checked) => handleCategorySelection(checked, key)}
                checked={checkIsSubsetArray(value, categorizedData[key].catFilters)}
              />
              {categorizedData[key].catName}
            </Typography>
            <Box display="flex" justifyContent="flex-start" flexDirection="column">
              {categorizedData[key].catFilters.map((filter) => (
                <FormControlLabel
                  key={filter.DataTypeId}
                  value={filter.DataTypeId}
                  control={
                    <Checkbox
                      onChange={(_, checked) => handleFilterSelection(checked, filter)}
                      checked={value.includes(filter)}
                      color="primary"
                    />
                  }
                  label={filter.DataTypeDesc}
                  className={classes.checkboxFilterLabel}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Select>
    </FormControl>
  )
}
