import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import { actionOptions, remove } from "../../constants/Constants";

export default function ActionTypeDropDownList({name, inputName, helperText, error, selectedValue, disabled, inputValue, onChange, onInput}) {

    return (
        <FormControl fullWidth margin="dense" error={error}>
            <Select name={name} value={selectedValue} variant="outlined" disabled={disabled} onChange={onChange}>
              {actionOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {selectedValue === remove.value &&
              <TextField 
                variant="outlined"
                name={inputName}
                onChange={onInput}
                error={error}
                label="Iesaldētā summa (EUR)"
                margin="dense"
                value={inputValue}
                fullWidth
              />
            }    
            {error && 
              <Box ml={1}>
                <FormHelperText>{helperText}</FormHelperText>
              </Box> 
            }          
        </FormControl>
    )
}