import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useQuery } from "react-query";
import { fetchMonitoringStatuses } from "../../api/API";

const useStyles = makeStyles((theme) => ({
  select: {
    width: 170,
  },
}));

export default function MonitoringStatusesDropDownList({
  label,
  fullWidth,
  variant = "standard",
  ...props
}) {
  const { data = [] } = useQuery(
    "monitoring-statuses",
    fetchMonitoringStatuses
  );
  const classes = useStyles();
  return (
    <FormControl
      className={classes.select}
      fullWidth={fullWidth}
      variant={variant}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...props}>
        {data.map(({ StatusId, StatusDesc }) => (
          <MenuItem key={StatusId} value={StatusId}>
            {StatusDesc}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
