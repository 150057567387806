import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import ModalAddEditProtectedPerson from './ModalAddEditProtectedPerson'
import CustomizedMaterialTable from '../../elements/CustomizedMaterialTable'
import DatePicker from '../../elements/DatePicker'
import ModalExchanges from './ModalExchanges'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import ModalTopUp from './ModalTopUp'
import { confirmDialog } from '../../elements/ConfirmDialog'
import { useMutation, useQuery } from 'react-query'
import { deletePerson, fetchPersonData, fetchPersonDataList } from '../../../api/API'
import { useSnackBar } from '../../providers/SnackBarProvider'
import ModalContract from './ModalContract'
import ModalChildren from './ModalChildren/ModalChildren'
import DescriptionIcon from '@material-ui/icons/Description'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import PersonAdd from '@material-ui/icons/PersonAdd'
import ModalAgreement from './ModalAgreement'
import TuneIcon from '@material-ui/icons/Tune'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { centsToEuros, formatNumber } from '../../../utils/Number'
import { PERSON_CODE_LENGTH } from '../../../constants/Constants'
import { addDashToPersonCode, normalizePersonCode } from '../../../utils/Misc'
import ModalDeleteStatus from './ModalDeleteStatus'
import moment from 'moment'
import ResetButton from '../../elements/ResetButton'
import DataTypeDropdown from '../../elements/DataTypeDropdown'
import ModalErrorDialog from './ModalErrorDialog'

const columns = [
  {
    field: 'PersonCode',
    title: 'Personas kods',
    render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
  },
  {
    field: 'PersonFirstName',
    title: 'Vārds',
    render: ({ PersonFirstName, PersonOtherNames }) =>
      [PersonFirstName, PersonOtherNames].join(' '),
  },
  { field: 'PersonLastName', title: 'Uzvārds' },
  {
    field: 'AnnualBalanceAmount',
    title: (
      <Box display="flex" alignItems="center">
        Lietotāja bilance kopš gada sākuma (EUR)
        <Tooltip title="Atspoguļo lietotāja kopējo bilanci uz esošo brīdi kopš gada sākuma. Šī bilance tiek nonullēta gada sākumā un tiek sākta skaitīt no jauna.">
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>
    ),
    render: ({ AnnualBalanceAmount }) => formatNumber(centsToEuros(AnnualBalanceAmount)),
  },
  {
    field: 'CurrentPayoutAmount',
    title: (
      <Box display="flex" alignItems="center">
        Tekošā gada bilances atlikums (EUR)
        <Tooltip title="Faktiskā bilance uz esošo brīdi, kas tiek aprēķināta no lietotāja kopējās bilances atņemot Tirgotāju datu apmaiņās iesniegtos faktiskos – piemērotos atbalsta apmērus. Tekošā bilance nevar būt lielāka par lietotāja kopējo bilanci.">
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>
    ),
    render: ({ CurrentPayoutAmount }) => formatNumber(centsToEuros(CurrentPayoutAmount)),
  },
  {
    field: 'Discounts',
    title: (
      <Box display="flex" alignItems="center">
        Pieejamās kategorijas
        <Tooltip title="Visas kategorijas, kas personai var tikt piemērotas, kas saņemtas no reģistriem. Tekošajā mēnesī var tikt piemērota daļa no tām. Piemēram, daudzbērnu ģimenē 2 personas, kur abām ir statuss, taču atbalsts tiek piešķirts tikai vienai no tām.">
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>
    ),
    render: ({ Discounts }) => (Discounts ? Object.values(Discounts)?.sort().join(', ') : ''),
  },
  {
    field: 'CurrentMonthDiscountAmount',
    title: (
      <Box display="flex" alignItems="center">
        Šomēnes piešķirtā summa
        <Tooltip title="Summa, kas tika piešķirta konkrētajā kalendāra mēnesī.">
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>
    ),
    render: ({ CurrentMonthDiscountAmount }) =>
      formatNumber(centsToEuros(CurrentMonthDiscountAmount)),
  },
  {
    field: 'CurrentMonthDiscountNames',
    title: (
      <Box display="flex" alignItems="center">
        Šomēnes piešķirtās kategorijas
        <Tooltip title="Kategorijas, kas tika piešķirtas konkrētajā kalendāra mēnesī.">
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>
    ),
    render: ({ CurrentMonthDiscountNames }) =>
      CurrentMonthDiscountNames?.filter((x) => x)
        .sort()
        .join(', '),
  },
]

const columnsHistory = [
  {
    field: 'EventDate',
    cellStyle: (_, rowData) => {
      return {
        textAlign: 'left',
        verticalAlign: 'top',
        padding: '20px',
        lineHeight: '20px',
        wordBreak: 'break-word',
        borderBottom: !rowData.isNextMonth ? '0px' : '',
      }
    },
  },
  {
    field: 'EventName',
    cellStyle: (_, rowData) => {
      return {
        textAlign: 'left',
        verticalAlign: 'top',
        padding: '20px',
        lineHeight: '20px',
        wordBreak: 'break-word',
        borderBottom: !rowData.isNextMonth ? '0px' : '',
      }
    },
    render: ({ EventType }) =>
      EventType?.map((x) => (
        <>
          {x.EventName ? (
            <div dangerouslySetInnerHTML={{ __html: x.EventName }} />
          ) : (
            x.EventData.map(() => <br />)
          )}
        </>
      )),
  },
  {
    field: 'EventData',
    cellStyle: (_, rowData) => {
      return {
        textAlign: 'left',
        verticalAlign: 'top',
        padding: '20px',
        lineHeight: '20px',
        wordBreak: 'break-word',
        borderBottom: !rowData.isNextMonth ? '0px' : '',
      }
    },
    render: ({ EventType }) =>
      EventType?.flatMap((x) => (
        <>
          {x.EventData.filter((x) => x).length > 0 ? (
            x.EventData.map((y) => <div dangerouslySetInnerHTML={{ __html: y }} />)
          ) : (
            <br />
          )}
        </>
      )),
  },
]

const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  searchField: {
    [theme.breakpoints.down('md')]: {
      margin: '0 16px',
    },
    width: 150
  },
  fixedWidth: {
    width: 150,
  },
  controlButtons: {
    [theme.breakpoints.down('md')]: {
      margin: '14px',
    },
  },
  searchButton: {
    marginTop: '8px',
  },
  resetButton: {
    margin: '0 8px',
  },
  validationText: {
    color: theme.palette.error.main,
  },
}))

const NAME_LENGTH_VALIDATION_MSG = 'Vārdam jābūt vismaz 3 simbolus garam'
const SURNAME_LENGTH_VALIDATION_MSG = 'Uzvārdam jābūt vismaz 3 simbolus garam'

export default function Home() {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [isMoreThanOnePerson, setIsMoreThanOnePerson] = useState(false)
  const [redirectedChildrenModalOpener, setRedirectedChildrenModalOpener] = useState(false)
  const [searchErrors, setSearchErrors] = React.useState({})
  const [personCode, setPersonCode] = React.useState('')
  const [intermediatePersonCode, setIntermediatePersonCode] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [rowsHistory, setRowsHistory] = React.useState([])
  const [selectedDataType, setSelectedDataType] = React.useState([])
  const [openProtectedPersonModal, setOpenProtectedPersonModal] = React.useState(false)
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false)
  const [openChildrenModal, setOpenChildrenModal] = React.useState(false)
  const [openContractModal, setOpenContractModal] = React.useState(false)
  const [openDeleteStatusModal, setOpenDeleteStatusModal] = React.useState(false)
  const [openAgreementEditModal, setOpenAgreementEditModal] = React.useState(false)
  const [openExchangesModal, setOpenExchangesModal] = React.useState(false)
  const [selectedPerson, setSelectedPerson] = React.useState(null)
  const startingMonth = moment().startOf('month').subtract(2, 'months')
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(startingMonth)
  const initialManualSearchState = {
    contractNumber: '',
    personName: '',
    personSurname: '',
  }
  const [manualSearchState, setManualSearchState] = React.useState(initialManualSearchState)

  const [deletePersonMutation] = useMutation(
    ({ PersonCode, reason }) => deletePerson({ PersonCode, Reason: reason }),
    {
      onSuccess: () => {
        showSnackBar()
        refetch()
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data}`,
        }),
    }
  )

  const { isLoading, refetch } = useQuery(
    [
      'persons-data',
      {
        personCode,
        contractNumber: manualSearchState.contractNumber,
        personName: manualSearchState.personName,
        personSurname: manualSearchState.personSurname,
      },
    ],
    (_, { personCode, contractNumber, personName, personSurname }) => {
      if (!contractNumber && !personName && !personSurname && !personCode) {
        return
      }

      return fetchPersonData({
        PersonCode: personCode || null,
        ContractNumber: contractNumber || null,
        FirstName: personName || null,
        LastName: personSurname || null,
      }).then((data) => {
        if (data) {
          setRows([data])

          setSelectedPerson(data)

          const dataTypeIds = selectedDataType.map((dataType) => dataType.DataTypeId)

          if (data.PersonCode) {
            return fetchPersonDataList({
              PersonCode: data.PersonCode,
              PeriodFrom: moment(selectedDateFrom).format('DD.MM.YYYY'),
              DataTypeList: dataTypeIds.length > 0 ? dataTypeIds : null,
            })
          }
        }

        setRows([])
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          setRowsHistory(
            data
              .sort(
                (a, b) =>
                  moment(b.EventDate, 'DD.MM.YYYY HH:mm:s').valueOf() -
                  moment(a.EventDate, 'DD.MM.YYYY HH:mm:s').valueOf()
              )
              .map((row, index, array) => {
                const next = array[index + 1]?.EventDate
                return {
                  ...row,
                  isNextMonth: next
                    ? moment(row.EventDate, 'DD.MM.YYYY').month() !==
                      moment(next, 'DD.MM.YYYY').month()
                    : true,
                }
              })
          )

          if (redirectedChildrenModalOpener) {
            setOpenChildrenModal(true)
            setRedirectedChildrenModalOpener(false)
          }
        } else {
          setRows([])
          setRowsHistory([])
        }
      },
      onError: (error) => {
        setIsMoreThanOnePerson(error?.response?.data?.error === 'Multiple rows found')
        console.error(error)
        setRowsHistory([])
      },
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )

  useEffect(() => {
    const normalizedPersonCode = normalizePersonCode(intermediatePersonCode)

    if (normalizedPersonCode.length === PERSON_CODE_LENGTH || normalizedPersonCode.length === 0) {
      setPersonCode(normalizedPersonCode)
    }
  }, [intermediatePersonCode])

  const onContractEdit = (_, person) => {
    setSelectedPerson(person)
    setOpenContractModal(true)
  }

  const onTopUpClick = (_, person) => {
    setSelectedPerson(person)
    setOpenTopUpModal(true)
  }

  const onChildrenEdit = (_, person) => {
    setSelectedPerson(person)
    setOpenChildrenModal(true)
  }

  const onPersonStatusDelete = (_, person) => {
    setSelectedPerson(person)
    setOpenDeleteStatusModal(true)
  }

  const onAgreementEdit = (_, person) => {
    setSelectedPerson(person)
    setOpenAgreementEditModal(true)
  }

  const onRowDelete = async (_, { PersonCode, PersonFirstName, PersonLastName }) => {
    const field = { label: 'Iemesls', id: 'reason' }
    const confirm = await confirmDialog({
      text: 'Vai tiešām vēlaties dzēst lietotāju no datubāzes?',
      description: `Lietotājs: ${PersonFirstName} ${PersonLastName}`,
      fields: [field],
    })
    if (confirm.ok) {
      const reason = confirm.fields.find((f) => f.id === field.id).value
      deletePersonMutation({ PersonCode, reason })
    }
  }

  const onHistoryClick = (_, person) => {
    setSelectedPerson(person)
    setOpenExchangesModal(true)
  }

  const resetFilters = () => {
    setPersonCode('')
    setIntermediatePersonCode('')
    setSelectedDataType([])
    setSelectedDateFrom(startingMonth)
    setManualSearchState(initialManualSearchState)
    // Workaround to reset persons data
    setRows([])
    setSearchErrors({})
  }

  const onFieldChange = ({ target }) => {
    setManualSearchState({ ...manualSearchState, [target.name]: target.value })
    setSearchErrors({ ...searchErrors, [target.name]: '' })
  }

  const isSearchKeysValid = () => {
    const { contractNumber, personName, personSurname } = manualSearchState
    const _errors = []

    if (contractNumber && contractNumber.length < 8)
      _errors['contractNumber'] = 'Līguma numuram jābūt vismaz 8 simbolus garam'

    if (personName && personName.length < 3) _errors['personName'] = NAME_LENGTH_VALIDATION_MSG

    if (personSurname && !personName) {
      _errors['personName'] = NAME_LENGTH_VALIDATION_MSG
    }

    if (personSurname && personSurname.length < 3)
      _errors['personSurname'] = SURNAME_LENGTH_VALIDATION_MSG

    if (personName && !personSurname) {
      _errors['personSurname'] = SURNAME_LENGTH_VALIDATION_MSG
    }

    setSearchErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const handleChildrenModal = (personCode) => {
    setOpenChildrenModal(false)

    if (personCode) {
      setIntermediatePersonCode(personCode)
      const normalizedPersonCode = normalizePersonCode(intermediatePersonCode)

      if (normalizedPersonCode.length === PERSON_CODE_LENGTH || normalizedPersonCode.length === 0) {
        setManualSearchState(initialManualSearchState)
        setPersonCode(normalizedPersonCode)
        setRedirectedChildrenModalOpener(true)
      }
    }
  }

  /**
   * Listen on personCode, selectedDateFrom and
   * selectedDataType changes to automatically
   * refetch persons data and history data
   */
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personCode, selectedDateFrom, selectedDataType])

  return (
    <>
      <ModalTopUp
        open={openTopUpModal}
        onSuccess={() => {
          refetch()
        }}
        onClose={() => setOpenTopUpModal(false)}
        person={selectedPerson}
      />
      <ModalAddEditProtectedPerson
        open={openProtectedPersonModal}
        onSuccess={() => {
          refetch()
        }}
        onClose={() => setOpenProtectedPersonModal(false)}
      />
      {openChildrenModal && (
        <ModalChildren
          person={selectedPerson}
          onSuccess={() => {
            refetch()
          }}
          onClose={(personCode) => handleChildrenModal(personCode)}
        />
      )}
      {openExchangesModal && (
        <ModalExchanges
          personCode={selectedPerson.PersonCode}
          onClose={() => setOpenExchangesModal(false)}
        />
      )}
      {openContractModal && (
        <ModalContract
          person={selectedPerson}
          refetchHistoryData={() => {
            refetch()
          }}
          onClose={() => setOpenContractModal(false)}
        />
      )}
      {openDeleteStatusModal && (
        <ModalDeleteStatus
          person={selectedPerson}
          onSuccess={() => {
            refetch()
          }}
          onClose={() => setOpenDeleteStatusModal(false)}
        />
      )}
      {isMoreThanOnePerson && <ModalErrorDialog onClose={() => setIsMoreThanOnePerson(false)} />}
      {openAgreementEditModal && (
        <ModalAgreement
          person={selectedPerson}
          onSuccess={() => {
            refetch()
          }}
          onClose={() => setOpenAgreementEditModal(false)}
        />
      )}
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <div className={classes.filterWrapper}>
          <TextField
            variant="standard"
            label="Personas kods"
            className={classes.searchField}
            value={intermediatePersonCode}
            onChange={({ target: { value } }) => setIntermediatePersonCode(value)}
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />
              ),
            }}
          />
          <Box ml={2}>
            <DatePicker
              className={classes.fixedWidth}
              views={['year', 'month']}
              openTo="year"
              format="MMMM YYYY"
              InputProps={{ readOnly: true }}
              value={selectedDateFrom}
              inputVariant="standard"
              margin="none"
              label="Vēstures dati no"
              maxDate={moment()}
              onChange={(date) => setSelectedDateFrom(date)}
              openOnInputClick
            />
          </Box>
          <Box ml={2} mr={2}>
            <DataTypeDropdown
              label="Datu veids"
              value={selectedDataType}
              onChange={(selectedItems) => {
                const uniqueDataTypeItems = [
                  ...new Map(selectedItems.map((item) => [item.DataTypeId, item])).values(),
                ]
                setSelectedDataType(uniqueDataTypeItems)
              }}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <div className={classes.filterWrapper}>
              <TextField
                variant="standard"
                label="Līguma numurs"
                name="contractNumber"
                className={classes.searchField}
                error={Boolean(searchErrors.contractNumber)}
                helperText={searchErrors.contractNumber}
                value={manualSearchState.contractNumber}
                onChange={onFieldChange}
              />
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box display="flex" alignItems="flex-end">
                  <Box ml={2} mr={2}>
                    <TextField
                      variant="standard"
                      label="Vārds"
                      name="personName"
                      error={Boolean(searchErrors.personName)}
                      helperText={searchErrors.personName}
                      value={manualSearchState.personName}
                      onChange={onFieldChange}
                    />
                  </Box>
                  <Typography>UN</Typography>
                  <Box ml={2} mr={2}>
                    <TextField
                      variant="standard"
                      label="Uzvārds"
                      name="personSurname"
                      error={Boolean(searchErrors.personSurname)}
                      helperText={searchErrors.personSurname}
                      value={manualSearchState.personSurname}
                      onChange={onFieldChange}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
          <Box display="flex" alignItems="center" className={classes.controlButtons}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              className={classes.searchButton}
              onClick={() => {
                if (!isSearchKeysValid()) return
                refetch()
              }}
            >
              Meklēt
            </Button>
            <ResetButton className={classes.resetButton} onClick={resetFilters} />
          </Box>
        </div>
        <Box display="flex" flexDirection="column">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ marginTop: 8 }}
            onClick={() => {
              setSelectedPerson(null)
              setOpenProtectedPersonModal(true)
            }}
          >
            Pievienot jaunu AL
          </Button>
        </Box>
      </Box>
      <CustomizedMaterialTable
        columns={columns}
        data={rows}
        isLoading={isLoading}
        options={{
          showTitle: false,
          toolbar: false,
          padding: 'default',
          paging: false,
        }}
        actions={[
          {
            icon: () => <DescriptionIcon />,
            onClick: onContractEdit,
            tooltip: 'Labot līguma informāciju',
          },
          {
            icon: () => <AccountBalanceWalletIcon />,
            onClick: onTopUpClick,
            tooltip: 'Labot konta bilanci',
          },
          {
            icon: () => <PersonAdd />,
            onClick: onChildrenEdit,
            tooltip: 'Labot bērnu piesaisti',
          },
          {
            icon: () => <TuneIcon />,
            onClick: onAgreementEdit,
            tooltip: 'Labot līgumu piešķirtajam atbalstam',
          },
          {
            icon: () => <SyncAltIcon />,
            onClick: onHistoryClick,
            tooltip: 'Aizsargātā lietotāja izmaiņu vēsture',
          },
          (rowData) => {
            const hasStatus = Boolean(rowData.StatussId)
            return {
              icon: 'backspace',
              tooltip: hasStatus ? 'Dzēst AL statusu' : '',
              onClick: onPersonStatusDelete,
              disabled: !hasStatus,
            }
          },
          {
            icon: 'delete',
            onClick: onRowDelete,
            tooltip: 'Dzēst',
          },
        ]}
      />
      <div style={{ padding: 25 }}>PERSONAS VĒSTURES DATI</div>
      <CustomizedMaterialTable
        key={personCode}
        columns={columnsHistory}
        data={rowsHistory}
        isLoading={isLoading}
        options={{
          header: false,
        }}
      />
    </>
  )
}
