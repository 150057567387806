import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import CustomizedMaterialTable from '../elements/CustomizedMaterialTable'
import DatePicker from '../elements/DatePicker'
import Button from '@material-ui/core/Button'
import FileExportIcon from 'mdi-material-ui/FileExport'
import { useQuery } from 'react-query'
import { fetchAuditData } from '../../api/API'
import moment from 'moment'
import useDebounce from '../../hooks/useDebounce'
import UsersDropDownList from '../elements/UsersDropDownList'
import ResetButton from '../elements/ResetButton'
import { addDashToPersonCode, normalizePersonCode } from '../../utils/Misc'
import { ALL_USERS_ID, PERSON_CODE_LENGTH, ALL_OPERATIONS_ID } from '../../constants/Constants'
import AuditTypesDropDownList from '../elements/AuditTypesDropDownList'
import { xlsxExporter } from '../../utils/utils'

const columns = [
  {
    field: 'PersonCode',
    title: 'Personas kods',
    render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
  },
  {
    field: 'PersonFirstName',
    title: 'Vārds',
  },
  { field: 'PersonLastName', title: 'Uzvārds' },
  {
    field: 'ActionDate',
    title: 'Datums, laiks',
    render: ({ ActionDate }) => moment(ActionDate).format('DD.MM.YYYY HH:mm:ss'),
  },
  {
    field: 'UserData',
    title: 'Lietotājs',
    render: ({ UserData, ProviderName }) => UserData || ProviderName,
  },
  {
    field: 'ActionName',
    title: 'Darbības',
    render: ({ ActionName, Comment }) => (
      <Box display="flex" alignItems="center">
        {ActionName}
        {Comment && (
          <Tooltip title={Comment}>
            <InfoIcon color="primary" style={{ width: 20, height: 20, marginLeft: 4 }} />
          </Tooltip>
        )}
      </Box>
    ),
  },
]

const useStyles = makeStyles({
  searchWrapper: {
    marginBottom: 24,
  },
  fixedWidth: {
    width: 150,
  },
})

export default function Audits() {
  const classes = useStyles()
  const [personCode, setPersonCode] = React.useState('')
  const debouncedPersonCode = useDebounce(personCode)
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(moment().startOf('month'))
  const [selectedDateTo, setSelectedDateTo] = React.useState(moment())
  const [selectedUser, setSelectedUser] = React.useState(ALL_USERS_ID)
  const [selectedAuditType, setSelectedAuditType] = React.useState(ALL_OPERATIONS_ID)
  const [rows, setRows] = React.useState([])

  const { refetch: refetchAuditData, isLoading } = useQuery(
    [
      'audits',
      {
        selectedUser,
        selectedDateFrom,
        selectedDateTo,
        personCode: debouncedPersonCode,
        selectedAuditType,
      },
    ],
    (_, { selectedUser, selectedDateFrom, selectedDateTo, personCode, selectedAuditType }) => {
      const normalizedPersonCode = normalizePersonCode(personCode)
      if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return
      return fetchAuditData({
        UserId: selectedUser !== ALL_USERS_ID ? selectedUser : null,
        PersonCode: normalizedPersonCode,
        ActionDateFrom: moment(selectedDateFrom).format('DD.MM.YYYY'),
        ActionDateTill: moment(selectedDateTo).format('DD.MM.YYYY'),
        ActionId: selectedAuditType !== ALL_OPERATIONS_ID ? selectedAuditType : null,
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          const parsedActionDateData = data.map((item) => ({
            ...item,
            ActionDate: moment(item.ActionDate, 'DD.MM.YYYY HH:mm:ss').toDate(),
          }))
          setRows(parsedActionDateData)
        } else {
          setRows([])
        }
      },
      onError: (error) => {
        console.error(error)
        setRows([])
      },
    }
  )

  const exportActionsLogToCSV = () => {
    const columnTitles = columns.map((column) => ({ title: column.title }))
    const data = [columnTitles.map((column) => `${column.title}`)]
    rows.forEach(
      ({ PersonFirstName, PersonLastName, PersonCode, ActionDate, UserData, ActionName }) => {
        const formattedLine = [
          addDashToPersonCode(PersonCode),
          PersonFirstName,
          PersonLastName,
          ActionDate,
          UserData,
          ActionName,
        ]
        data.push(formattedLine)
      }
    )

    const fileNameDate = `${moment(selectedDateFrom).format('DD.MM.YYYY')} - ${moment(
      selectedDateTo
    ).format('DD.MM.YYYY')}`
    const fileName = `Audits (${fileNameDate})`

    xlsxExporter(data, fileName)
  }

  const resetFilters = () => {
    setPersonCode('')
    setSelectedDateFrom(moment().startOf('month'))
    setSelectedDateTo(moment())
    setSelectedUser(ALL_USERS_ID)
    setSelectedAuditType(ALL_OPERATIONS_ID)
  }

  const handleAuditTypeChange = (value) => {
    setSelectedAuditType(value)
    refetchAuditData()
  }

  return (
    <>
      <Box className={classes.searchWrapper} flexWrap="wrap" display="flex" alignItems="center">
        <TextField
          variant="standard"
          label="Personas kods"
          className={classes.fixedWidth}
          value={personCode}
          onChange={({ target: { value } }) => setPersonCode(value)}
          InputProps={{
            endAdornment: (
              <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />
            ),
          }}
        />
        <Box ml={2} mr={2}>
          <DatePicker
            className={classes.fixedWidth}
            value={selectedDateFrom}
            inputVariant="standard"
            label="Periods no"
            onChange={(date) => {
              setSelectedDateFrom(date)
            }}
          />
        </Box>
        <Box mr={2}>
          <DatePicker
            className={classes.fixedWidth}
            value={selectedDateTo}
            inputVariant="standard"
            label="Periods līdz"
            onChange={(date) => {
              setSelectedDateTo(date)
            }}
          />
        </Box>
        <Box mr={2}>
          <UsersDropDownList
            label="Lietotāji"
            value={selectedUser}
            onChange={({ target: { value } }) => setSelectedUser(value)}
          />
        </Box>
        <Box mr={2}>
          <AuditTypesDropDownList
            value={selectedAuditType}
            onChange={({ target: { value } }) => handleAuditTypeChange(value)}
          />
        </Box>
        <ResetButton onClick={resetFilters} />
        <Box mt={2} ml="auto">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileExportIcon />}
            onClick={exportActionsLogToCSV}
          >
            Eksports
          </Button>
        </Box>
      </Box>
      <CustomizedMaterialTable
        key={personCode.concat(selectedDateFrom, selectedDateTo, selectedUser, selectedAuditType)}
        columns={columns}
        data={rows}
        isLoading={isLoading}
      />
    </>
  )
}
