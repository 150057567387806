import { Dialog, DialogContent, Box, Button, makeStyles } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
}))

export default function ModalErrorDialog({ onClose }) {
  const classes = useStyles()

  return (
    <Dialog open maxWidth="xs">
      <DialogContent>
        <Box pb={1}>
          <Box display="flex" justifyContent="flex-end">
            <CancelIcon className={classes.closeIcon} color="primary" onClick={onClose} />
          </Box>
          <Box px={2}>
            <Box display="flex" justifyContent="center">
              Pēc izvēlētajiem kritērijiem ir atrasta vairāk kā viena persona. Lūdzu precizējiet
              kritērijus un meklējiet vēlreiz!
            </Box>
          </Box>
          <Box px={2} mt={2} display="flex" justifyContent="center" alignItems="center">
            <Button color="primary" variant="contained" onClick={onClose}>
              Aizvērt
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
