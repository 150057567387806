import React from "react";
import { useQuery } from "react-query";
import {
    fetchNonPaymentMaintenanceData,
} from "../../../api/API";
import moment from "moment";
import { addDashToPersonCode, normalizePersonCode } from "../../../utils/Misc";
import { PERSON_CODE_LENGTH } from "../../../constants/Constants";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";

export default function NonPaymentMaintenance({ selectedPeriod, personCode }) {
    const [rows, setRows] = React.useState([]);
    const columnsUGFA = [
        {
            title: "Personas kods",
            field: "PersonCode",
            render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
        }
    ];

    const { isLoading } = useQuery(
        [
            "low-income-data",
            {
                personCode,
                selectedPeriod,
            },
        ],
        (_, { personCode, selectedPeriod }) => {
            const normalizedPersonCode = normalizePersonCode(personCode);
            if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return;
            return fetchNonPaymentMaintenanceData({
                PersonCode: normalizedPersonCode,
                UgfaStatusFrom: moment(selectedPeriod).format("DD.MM.YYYY"),
                UgfaStatusTill: moment(selectedPeriod)
                    .endOf("month")
                    .format("DD.MM.YYYY"),
            });
        },
        {
            onSuccess: (data) => {
                if (data) setRows(data);
                else setRows([]);
            },
            onError: (error) => {
                console.error(error);
                setRows([]);
            },
        }
    );

    return (
        <CustomizedMaterialTable
            columns={columnsUGFA}
            data={rows}
            isLoading={isLoading}
            options={{
                defaultExpanded: true,
            }}
        />
    );
}