import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import DatePicker from '../elements/DatePicker'
import useDebounce from '../../hooks/useDebounce'
import ResetButton from '../elements/ResetButton'
import FamilyMembers from '../elements/registry/FamilyMembers'
import LowIncomeFamilies from '../elements/registry/LowIncomeFamilies'
import NonPaymentMaintenance from '../elements/registry/NonPaymentMaintenance'
import Invalids from '../elements/registry/Invalids'
import Privileges from '../elements/registry/Privileges'
import SifPersonsIntegration from '../elements/registry/SifPersonsIntegration'

const useStyles = makeStyles({
  formControl: {
    minWidth: 150,
    marginRight: 16,
  },
  search: {
    width: 150,
  },
})

const SOURCES = {
  ALDIS: 'ALDIS',
  PMLP: 'PMLP',
  SIF: 'SIF',
  SOPA: 'SOPA',
  UGFA: 'UGFA',
  VDEĀVK: 'VDEĀVK',
}

export default function Registry() {
  const classes = useStyles()
  const [personCode, setPersonCode] = React.useState('')
  const debouncedPersonCode = useDebounce(personCode)
  const [selectedSource, setSelectedSource] = React.useState(SOURCES.PMLP)
  const startingMonth = moment().startOf('month')
  const [selectedPeriod, setSelectedPeriod] = React.useState(startingMonth)

  const resetFilters = () => {
    setPersonCode('')
    setSelectedPeriod(startingMonth)
  }

  return (
    <>
      <Box my={2} display="flex" flexWrap="wrap">
        <FormControl className={classes.formControl}>
          <InputLabel>Reģistrs</InputLabel>
          <Select
            value={selectedSource}
            onChange={({ target: { value } }) => setSelectedSource(value)}
          >
            {Object.values(SOURCES).map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box mr={2}>
          <DatePicker
            className={classes.search}
            views={['year', 'month']}
            openTo="year"
            format="MMMM YYYY"
            InputProps={{ readOnly: true }}
            value={selectedPeriod}
            inputVariant="standard"
            label="Periods"
            margin="none"
            maxDate={moment()}
            onChange={(date) => setSelectedPeriod(date)}
            openOnInputClick
          />
        </Box>
        <FormControl>
          <TextField
            variant="standard"
            label="Personas kods"
            className={classes.search}
            value={personCode}
            onChange={({ target: { value } }) => setPersonCode(value)}
            InputProps={{
              endAdornment: (
                <SearchIcon style={{ opacity: 0.2, marginRight: '6px' }} onClick={null} />
              ),
            }}
          />
        </FormControl>
        <ResetButton onClick={resetFilters} />
      </Box>
      {selectedSource === SOURCES.PMLP && (
        <FamilyMembers personCode={debouncedPersonCode} selectedPeriod={selectedPeriod} />
      )}
      {selectedSource === SOURCES.SOPA && (
        <LowIncomeFamilies personCode={debouncedPersonCode} selectedPeriod={selectedPeriod} />
      )}
      {selectedSource === SOURCES.UGFA && (
        <NonPaymentMaintenance personCode={debouncedPersonCode} selectedPeriod={selectedPeriod} />
      )}
      {selectedSource === SOURCES.VDEĀVK && (
        <Invalids personCode={debouncedPersonCode} selectedPeriod={selectedPeriod} />
      )}
      {selectedSource === SOURCES.ALDIS && (
        <Privileges personCode={debouncedPersonCode} selectedPeriod={selectedPeriod} />
      )}
      {selectedSource === SOURCES.SIF && (
        <SifPersonsIntegration personCode={debouncedPersonCode} selectedPeriod={selectedPeriod} />
      )}
    </>
  )
}
