import moment from 'moment'
import lvLocale from 'moment/locale/lv'
import { registersIds, contractsIds, supportIds, statusChangeIds } from '../constants/Constants'
import { writeFile, utils } from 'xlsx'

moment.updateLocale('lv', lvLocale)

export function getPeriods(appendPrevious = 12, appendNext = 0) {
  const dateArray = []
  const historyMonth = moment().local().subtract(appendPrevious, 'month').startOf('month')
  const futureMonth = moment().local().add(appendNext, 'month').endOf('month')
  let currentDate = historyMonth

  while (currentDate <= futureMonth) {
    dateArray.push({
      label: moment(currentDate).format('MMMM YYYY'),
      value: moment(currentDate),
    })
    currentDate = moment(currentDate).add(1, 'month')
  }
  return dateArray
}

export const mapFiltersToCategories = (filterData) => {
  const categorizedData = {
    support: {
      catName: 'Atbalsts',
      catFilters: [],
      availableOptions: supportIds,
    },
    contracts: {
      catName: 'Līgumi',
      catFilters: [],
      availableOptions: contractsIds,
    },
    registers: {
      catName: 'Reģistri',
      catFilters: [],
      availableOptions: registersIds,
    },
    statusChange: {
      catName: 'Statusa maiņa',
      catFilters: [],
      availableOptions: statusChangeIds,
    },
  }

  for (let i = 0; i < filterData.length; i++) {
    if (registersIds.has(filterData[i].DataTypeId)) {
      categorizedData.registers.catFilters = [
        ...categorizedData.registers.catFilters,
        filterData[i],
      ]
      continue
    }

    if (contractsIds.has(filterData[i].DataTypeId)) {
      categorizedData.contracts.catFilters = [
        ...categorizedData.contracts.catFilters,
        filterData[i],
      ]
      continue
    }

    if (supportIds.has(filterData[i].DataTypeId)) {
      categorizedData.support.catFilters = [...categorizedData.support.catFilters, filterData[i]]
      continue
    }

    if (statusChangeIds.has(filterData[i].DataTypeId)) {
      categorizedData.statusChange.catFilters = [
        ...categorizedData.statusChange.catFilters,
        filterData[i],
      ]
      continue
    }
  }

  return categorizedData
}

export const checkIsSubsetArray = (parentArray, subsetArray) => {
  return subsetArray?.every((el) => {
    return parentArray?.includes(el)
  })
}

export const csvExporter = (lineArray, fileName) => {
  const csvContent = lineArray.join('\n')
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri)
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const xlsxExporter = (data, fileName) => {
  const workbook = utils.book_new()
  const worksheet = utils.aoa_to_sheet(data)
  // Calculate column widths based on data length
  const columnWidths = []
  utils.sheet_to_json(worksheet, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = String(cell).length
      if (!columnWidths[colIndex] || columnWidths[colIndex] < cellLength) {
        columnWidths[colIndex] = cellLength
      }
    })
  })
  worksheet['!cols'] = columnWidths.map((width) => ({ wch: width }))
  utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const filePath = `${fileName}.xlsx`

  writeFile(workbook, filePath)
}

export const sortOverviewDataByDate = (data, keyName) => {
  const mappedData = data.map((item) => {
    const dayOfMonth = 1
    const [month, year] = item[keyName].split('.')
    const parsedYear = parseInt(year)
    const parsedMonth = parseInt(month) - 1
    return {
      ...item,
      [keyName]: new Date(parsedYear, parsedMonth, dayOfMonth).toISOString(),
    }
  })

  mappedData.sort((a, b) => {
    return new Date(a[keyName]) - new Date(b[keyName])
  })

  return mappedData
}

export const getDateWithCustomMonth = (monthFrom, yearFrom) => {
  const dayOfMonth = 1
  const monthNumber = moment(monthFrom).month()
  const yearNumber = yearFrom ? moment(yearFrom).year() : moment().year()
  const dateWithMonthField = moment(new Date(yearNumber, monthNumber, dayOfMonth))

  const reportFromDate = moment(dateWithMonthField).format('DD.MM.YYYY')
  const reportTillDate = moment(dateWithMonthField).endOf('month').format('DD.MM.YYYY')

  return {
    reportFromDate,
    reportTillDate,
  }
}
