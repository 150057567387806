import React from 'react'
import MaterialTable from 'material-table'

export default function CustomizedMaterialTable({ options, components, isLoading, ...props }) {
  return (
    <MaterialTable
      {...props}
      options={{
        filtering: false,
        search: false,
        pageSize: 20,
        padding: 'dense',
        emptyRowsWhenPaging: false,
        pageSizeOptions: [5, 10, 20, 50],
        actionsColumnIndex: props.columns.length,
        paginationPosition: 'both',
        rowStyle: { fontSize: '0.875rem', fontFamily: 'Roboto' },
        ...options,
      }}
      isLoading={isLoading}
      localization={{
        pagination: {
          labelRowsSelect: 'rindas',
          labelDisplayedRows: `{from}-{to} no {count}`,
          firstTooltip: 'Pirmā lapa',
          nextTooltip: 'Nākamā lapa',
          previousTooltip: 'Iepriekšējā lapa',
          lastTooltip: 'Pēdējā lapa',
        },
        body: {
          emptyDataSourceMessage: 'Nav datu',
        },
        header: {
          actions: '',
        },
      }}
      components={{
        // hide toolbar & groupbar by default
        Toolbar: () => null,
        Groupbar: () => null,
        ...components,
      }}
    />
  )
}
