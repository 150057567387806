import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  overviewMenuPaper: {
    top: '150px !important',
  },
  overviewMenuList: {
    padding: 0,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
}))

export const OverviewMenu = ({ dropdownMenuContainer, onClose }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Menu
      anchorEl={dropdownMenuContainer}
      open={Boolean(dropdownMenuContainer)}
      onClose={onClose}
      PaperProps={{ className: classes.overviewMenuPaper }}
      MenuListProps={{ className: classes.overviewMenuList }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      getContentAnchorEl={null}
    >
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          onClose()
          history.push('/pārskati/bilance-al')
        }}
      >
        AL detalizēta bilance
      </MenuItem>
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          onClose()
          history.push('/pārskati/atbalsts-al')
        }}
      >
        Piešķirtais un izmantotais atbalsts sadalījumā pa AL
      </MenuItem>
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          onClose()
          history.push('/pārskati/atbalsts-tirgotājiem')
        }}
      >
        Piešķirtais un izmantotais atbalsts sadalījumā pa tirgotājiem
      </MenuItem>
    </Menu>
  )
}
