import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProvidersDropDownList from '../../elements/ProvidersDropDownList'
import { useMutation, useQuery } from 'react-query'
import { deleteContract, editContract, fetchContractData } from '../../../api/API'
import moment from 'moment'
import { useSnackBar } from '../../providers/SnackBarProvider'
import DatePicker from '../../elements/DatePicker'
import { confirmDialog } from '../../elements/ConfirmDialog'
import { defaultErrMsg } from '../../../constants/Constants'

const useStyles = makeStyles({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 4,
    textTransform: 'uppercase',
  },
  dialogSubtitle: {
    paddingBottom: 8,
    paddingTop: 8,
    textTransform: 'uppercase',
  },
  button: {
    minWidth: 130,
  },
})

export default function ModalContract({ onClose, person, refetchHistoryData }) {
  const classes = useStyles()
  return (
    <Dialog open onClose={onClose}>
      <Box pb={2}>
        <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon className={classes.closeIcon} color="primary" onClick={onClose} />
        </Box>
        <Box px={2}>
          <DialogTitle className={classes.dialogTitle}>
            <Box display="flex" justifyContent="center">
              Labot līguma informāciju
            </Box>
          </DialogTitle>
          <Form person={person} onClose={onClose} refetchHistoryData={refetchHistoryData} />
        </Box>
      </Box>
    </Dialog>
  )
}

function Form({ person, onClose, refetchHistoryData }) {
  const isEditMode = Boolean(person)
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()

  //form data
  const initialFormData = {
    selectedPerson: person,
    contractNumber: '',
    provider: '',
    contractEffectFrom: null,
    contractEffectTill: null,
  }
  const [formState, setFormState] = useState(initialFormData)
  const [formStateReadOnly, setFormStateReadOnly] = useState(initialFormData)

  //form errors
  const [formErrors, setFormErrors] = useState({})

  useQuery(
    [
      'contract-by-person-code',
      {
        person,
      },
    ],
    (_, { person }) => {
      if (!isEditMode) return
      return fetchContractData({
        PersonCode: person.PersonCode,
        ProviderId: null,
        ContractNumber: '',
        ContractEffectFrom: '01.01.1970',
        ContractEffectTill: '01.01.2200', // TODO: dates should be optional
      })
    },
    {
      onSuccess: (data) => {
        if (data?.length > 0) {
          const contractData = data[0]

          setFormStateReadOnly({
            ...formStateReadOnly,
            contractNumber: contractData.ContractNumber,
            provider: contractData.ProviderId,
            contractEffectFrom: contractData.ContractEffectFrom
              ? moment(contractData.ContractEffectFrom, 'DD.MM.YYYY')
              : null,
            contractEffectTill: contractData.ContractEffectTill
              ? moment(contractData.ContractEffectTill, 'DD.MM.YYYY')
              : null,
          })
        }
      },
    }
  )

  const [editContractMutation, { isLoading: isLoadingFormEdit }] = useMutation(
    ({ provider, contractNumber, selectedPerson, contractEffectFrom, contractEffectTill }) =>
      editContract({
        ProviderId: provider,
        PersonCode: selectedPerson.PersonCode,
        ContractNumber: contractNumber,
        ContractEffectFrom: moment(contractEffectFrom).format('DD.MM.YYYY'),
        ContractEffectTill: contractEffectTill
          ? moment(contractEffectTill).format('DD.MM.YYYY')
          : '',
      }),
    {
      onSuccess: (data) => {
        const warning = data?.data?.Warning || undefined

        showSnackBar({
          severity: warning && 'warning',
          text: warning,
        })
        onClose()
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data?.error}`,
        })
      },
      onSettled: () => {
        refetchHistoryData()
      },
    }
  )

  const [deleteContractMutation, { isLoading: isLoadingDeleteForm }] = useMutation(
    ({ provider, contractNumber, selectedPerson }) =>
      deleteContract({
        ProviderId: provider,
        PersonCode: selectedPerson.PersonCode,
        ContractNumber: contractNumber,
      }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data?.error}`,
        }),
      onSettled: () => {
        refetchHistoryData()
      },
    }
  )

  const onFieldChange = ({ target }) => {
    setFormState({ ...formState, [target.name]: target.value })
    setFormErrors({ ...formErrors, [target.name]: '' })
  }

  const isFormValid = () => {
    const { contractNumber, provider, contractEffectFrom } = formState

    const _errors = []
    if (!contractNumber) _errors['contractNumber'] = defaultErrMsg
    if (!provider) _errors['provider'] = defaultErrMsg
    if (!contractEffectFrom) _errors['contractEffectFrom'] = defaultErrMsg

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onSubmit = () => {
    if (!isFormValid()) return
    editContractMutation({
      selectedPerson: formStateReadOnly.selectedPerson,
      contractNumber: formState.contractNumber,
      provider: formState.provider,
      contractEffectFrom: formState.contractEffectFrom,
      contractEffectTill: formState.contractEffectTill,
    })
  }

  const onDelete = async () => {
    const confirm = await confirmDialog({
      text: 'Vai tiešām vēlaties dzēst līgumu?',
    })
    if (confirm.ok)
      deleteContractMutation({
        selectedPerson: formStateReadOnly.selectedPerson,
        contractNumber: formStateReadOnly.contractNumber,
        provider: formStateReadOnly.provider,
      })
  }

  return (
    <>
      <DialogContent>
        <TextField
          disabled
          fullWidth
          variant="outlined"
          margin="dense"
          label="Personas kods"
          value={formStateReadOnly.selectedPerson?.PersonCode}
        />
        <TextField
          variant="outlined"
          margin="dense"
          label="Vārds"
          fullWidth
          value={[
            formStateReadOnly.selectedPerson?.PersonFirstName,
            formStateReadOnly.selectedPerson?.PersonOtherNames,
          ].join(' ')}
          disabled
        />
        <TextField
          variant="outlined"
          margin="dense"
          label="Uzvārds"
          fullWidth
          value={formStateReadOnly.selectedPerson?.PersonLastName || ''}
          disabled
        />
        <TextField
          variant="outlined"
          label="Līguma Nr."
          value={formStateReadOnly.contractNumber}
          margin="dense"
          fullWidth
          disabled
        />
        <ProvidersDropDownList
          variant="outlined"
          margin="dense"
          fullWidth
          label="Pakalpojuma sniedzējs"
          value={formStateReadOnly.provider}
          disabled
        />
        <Box display="flex" justifyContent="space-between">
          <DatePicker label="Periods no" value={formStateReadOnly.contractEffectFrom} disabled />
          <DatePicker label="Periods līdz" value={formStateReadOnly.contractEffectTill} disabled />
        </Box>

        <Box display="flex" justifyContent="flex-end" marginTop="16px">
          <Button
            className={classes.button}
            variant="contained"
            onClick={onDelete}
            color="secondary"
            disabled={isLoadingDeleteForm || isLoadingFormEdit}
          >
            {isLoadingDeleteForm ? <CircularProgress color="inherit" size={18} /> : 'Dzēst līgumu'}
          </Button>
        </Box>

        <Box px={3}>
          <DialogTitle className={classes.dialogSubtitle}>
            <Box display="flex" justifyContent="center">
              Jaunais līgums
            </Box>
          </DialogTitle>
        </Box>
        <TextField
          variant="outlined"
          label="Līguma Nr."
          value={formState.contractNumber}
          name="contractNumber"
          margin="dense"
          fullWidth
          onChange={onFieldChange}
          helperText={formErrors.contractNumber}
          error={Boolean(formErrors.contractNumber)}
        />
        <ProvidersDropDownList
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          name="provider"
          label="Pakalpojuma sniedzējs"
          value={formState.provider}
          onChange={onFieldChange}
          helperText={formErrors.provider}
          error={Boolean(formErrors.provider)}
        />
        <Box display="flex" justifyContent="space-between">
          <DatePicker
            label="Periods no"
            value={formState.contractEffectFrom}
            onChange={(date) => {
              setFormState({
                ...formState,
                contractEffectFrom: date,
              })
              setFormErrors({
                ...formErrors,
                contractEffectFrom: '',
              })
            }}
            error={Boolean(formErrors.contractEffectFrom)}
            helperText={formErrors.contractEffectFrom}
          />
          <DatePicker
            label="Periods līdz"
            value={formState.contractEffectTill}
            onChange={(date) => {
              setFormState({
                ...formState,
                contractEffectTill: date,
              })
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box px={2}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={onSubmit}
            color="primary"
            disabled={isLoadingFormEdit || isLoadingDeleteForm}
          >
            {isLoadingFormEdit ? <CircularProgress color="inherit" size={18} /> : 'Saglabāt'}
          </Button>
        </Box>
      </DialogActions>
    </>
  )
}
