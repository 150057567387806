import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ModalAddEditProvider from "./ModalAddEditProvider";
import ModalAddEditDiscount from "./ModalAddEditDiscount";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";
import { useQuery } from "react-query";
import { fetchDiscountData, fetchProviderTypes } from "../../../api/API";
import { centsToEuros, formatNumber } from "../../../utils/Number";
import moment from "moment";

const CLASSIFIER_TYPES = {
  DISCOUNTS: "Kategorija", //TODO: rename this
  PROVIDERS: "Tirgotājs",
};

const providerColumns = [
  { field: "ProviderName", title: "Nosaukums" },
  { field: "ProviderRegNr", title: "Reģistrācijas Nr." },
  { field: "ProviderUUID", title: "Atslēga" },
  { field: "ProviderAddress", title: "Adrese" },
  { field: "ProviderVatNr", title: "PVN numurs" },
  { field: "ProviderBankName", title: "Banka" },
  { field: "ProviderBankAccount", title: "Bankas konts" },
  { field: "ProviderValidFrom", title: "No" },
  { field: "ProviderValidTill", title: "Līdz" },
];

const discountColumns = [
  { field: "DiscountTypeDesc", title: "Kategorija" },
  {
    field: "DiscountSum",
    title: "Atbalsta apmērs (EUR)",
    render: ({ DiscountSum }) => formatNumber(centsToEuros(DiscountSum)),
  },
  {
    field: "DiscountValidFrom",
    title: "No",
    // defaultSort: "desc",
    customSort: (a, b) =>
      moment(a.DiscountValidFrom, "DD.MM.YYYY").valueOf() -
      moment(b.DiscountValidFrom, "DD.MM.YYYY").valueOf(),
  },
  { field: "DiscountValidTill", title: "Līdz" },
];

const useStyles = makeStyles({
  formControl: {
    minWidth: 150,
  },
  searchWrapper: {},
});

export default function Classifiers() {
  const classes = useStyles();
  const [selectedClassifier, setSelectedClassifier] = React.useState(
    CLASSIFIER_TYPES.PROVIDERS
  );

  return (
    <>
      <Box
        className={classes.searchWrapper}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControl className={classes.formControl}>
          <InputLabel>Klasifikators</InputLabel>
          <Select
            value={selectedClassifier}
            onChange={({ target: { value } }) => setSelectedClassifier(value)}
          >
            {Object.values(CLASSIFIER_TYPES).map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selectedClassifier === CLASSIFIER_TYPES.PROVIDERS ? (
        <Providers />
      ) : (
        <Discounts />
      )}
    </>
  );
}

function Discounts() {
  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false);
  const [selectedDiscount, setSelectedDiscount] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const { isLoading, refetch } = useQuery("discounts", fetchDiscountData, {
    onSuccess: (data) => {
      if (data) setRows(data);
      else setRows([]);
    },
  });

  const onRowEdit = (_, discount) => {
    setSelectedDiscount(discount);
    setOpenModalAddEdit(true);
  };

  const closeModal = () => {
    setSelectedDiscount(null);
    setOpenModalAddEdit(false);
    refetch();
  };

  return (
    <>
      {openModalAddEdit && (
        <ModalAddEditDiscount
          discount={selectedDiscount}
          onClose={closeModal}
        />
      )}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setOpenModalAddEdit(true);
          }}
        >
          pievienot jaunu kategoriju
        </Button>
      </Box>
      <CustomizedMaterialTable
        columns={discountColumns}
        data={rows}
        isLoading={isLoading}
        actions={[
          {
            icon: "edit",
            onClick: onRowEdit,
            tooltip: "Rediģēt",
          },
        ]}
      />
    </>
  );
}

function Providers() {
  const [openModalAddEdit, setOpenModalAddEdit] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const { isLoading, refetch } = useQuery("providers", fetchProviderTypes, {
    onSuccess: (data) => {
      if (data) setRows(data);
      else setRows([]);
    },
  });

  const onRowEdit = (_, provider) => {
    setSelectedProvider(provider);
    setOpenModalAddEdit(true);
  };

  const closeModal = () => {
    setSelectedProvider(null);
    setOpenModalAddEdit(false);
    refetch();
  };

  return (
    <>
      {openModalAddEdit && (
        <ModalAddEditProvider
          provider={selectedProvider}
          onClose={closeModal}
        />
      )}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedProvider(null);
            setOpenModalAddEdit(true);
          }}
        >
          pievienot jaunu tirgotāju
        </Button>
      </Box>
      <CustomizedMaterialTable
        columns={providerColumns}
        data={rows}
        isLoading={isLoading}
        actions={[
          {
            icon: "edit",
            onClick: onRowEdit,
            tooltip: "Rediģēt",
          },
        ]}
      />
    </>
  );
}
