import React, { useState } from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

export default function DatePicker(props) {
  const [isOpen, setIsOpen] = useState(false)

  const openPickerOnInputClickProps = props.openOnInputClick
    ? { open: isOpen, onClick: () => setIsOpen(true), onClose: () => setIsOpen(false) }
    : {}

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="lv">
      <KeyboardDatePicker
        inputVariant="outlined"
        invalidDateMessage="Datums nav derīgs"
        maxDateMessage="Datums nav derīgs"
        minDateMessage="Datums nav derīgs"
        disableToolbar
        variant="inline"
        format="DD.MM.YYYY"
        margin="dense"
        autoOk
        {...openPickerOnInputClickProps}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
