import React from "react";
import { useQuery } from "react-query";
import {
    fetchLowIncomeFamilyData,
} from "../../../api/API";
import moment from "moment";
import { addDashToPersonCode, normalizePersonCode } from "../../../utils/Misc";
import { PERSON_CODE_LENGTH } from "../../../constants/Constants";
import CustomizedMaterialTable from "../../elements/CustomizedMaterialTable";

export default function LowIncomeFamilies({ selectedPeriod, personCode }) {
    const [rows, setRows] = React.useState([]);
    const columnsSOPA = [
        { title: "Mājsaimniecības ID", field: "FamilyId", defaultGroupOrder: 0 },
        {
            title: "Personas kods",
            field: "PersonCode",
            cellStyle: { whiteSpace: "nowrap" },
            render: ({ PersonCode }) => addDashToPersonCode(PersonCode),
        },
        {
            title: "Vārds",
            field: "PersonFirstName",
            render: ({ PersonFirstName, PersonOtherNames }) =>
                [PersonFirstName, PersonOtherNames].join(" "),
        },
        { title: "Uzvārds", field: "PersonLastName" },
        {
            title: "Datums no",
            field: "LowIncomeStatusFrom",
            customSort: (a, b) =>
                moment(a.LowIncomeStatusFrom, "DD.MM.YYYY").valueOf() -
                moment(b.LowIncomeStatusFrom, "DD.MM.YYYY").valueOf(),
        },
        {
            title: "Datums līdz",
            field: "LowIncomeStatusTill",
            customSort: (a, b) =>
                moment(a.LowIncomeStatusTill, "DD.MM.YYYY").valueOf() -
                moment(b.LowIncomeStatusTill, "DD.MM.YYYY").valueOf(),
        },
        { title: "Adrese", field: "ObjectAddressText" },
        {
            title: "Līguma Nr.",
            field: "ContractNumber",
            cellStyle: { whiteSpace: "nowrap" },
        },
        { title: "Tirgotājs", field: "ProviderName" },
    ];
    
    const { isLoading } = useQuery(
        [
            "low-income-data",
            {
                personCode,
                selectedPeriod,
            },
        ],
        (_, { personCode, selectedPeriod }) => {
            const normalizedPersonCode = normalizePersonCode(personCode);
            if (normalizedPersonCode.length !== PERSON_CODE_LENGTH) return;
            return fetchLowIncomeFamilyData({
                PersonCode: normalizedPersonCode,
                ProviderId: null,
                FamilyId: null,
                LowIncomeStatusFrom: moment(selectedPeriod).format("DD.MM.YYYY"),
                LowIncomeStatusTill: moment(selectedPeriod)
                    .endOf("month")
                    .format("DD.MM.YYYY"),
            });
        },
        {
            onSuccess: (data) => {
                if (data) setRows(data);
                else setRows([]);
            },
            onError: (error) => {
                console.error(error);
                setRows([]);
            },
        }
    );

    return (
        <CustomizedMaterialTable
            columns={columnsSOPA}
            data={rows}
            isLoading={isLoading}
            options={{
                defaultExpanded: true,
            }}
        />
    );
}