import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CancelIcon from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from 'react-query'

import { fetchPersonsChildren, setPersonsChildren } from '../../../../api/API'
import {
  DISABLED_CHILDREN_DISCOUNT_TYPE,
  MULTIPLE_CHILDREN_DISCOUNT_TYPE,
} from '../../../../constants/Constants'
import { useSnackBar } from '../../../providers/SnackBarProvider'
import { confirmDialog } from '../../../elements/ConfirmDialog'
import { defaultErrMsg } from '../../../../constants/Constants'
import ParentRow from './ParentRow'
import ChildrenRows from './ChildrenRows'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 4,
    textTransform: 'uppercase',
  },
  contentBox: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
  },
  divider: {
    height: '4px',
    backgroundColor: theme.palette.primary.main,
    marginTop: '15px',
  },
  justificationBox: {
    marginTop: '18px',
  },
  justificationSaveButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  justificationSaveButton: {
    minWidth: '140px',
  },
}))

export default function ModalChildren({ onClose, onSuccess, person }) {
  const classes = useStyles()
  const { showSnackBar } = useSnackBar()
  const [multipleChildrenData, setMultipleChildrenData] = useState([])
  const [disabledChildrenData, setDisabledChildrenData] = useState([])
  const [multipleChildrenFormErrors, setMultipleChildrenFormErrors] = useState({})
  const [disabledChildrenFormErrors, setDisabledChildrenFormErrors] = useState({})
  const [isMultipleChildrenFormTouched, setIsMultipleChildrenFormTouched] = useState(false)
  const [isDisabledChildrenFormTouched, setIsDisabledChildrenFormTouched] = useState(false)
  const [multipleChildrenFormState, setMultipleChildrenFormState] = useState({
    PersonCode: person.PersonCode,
    DiscountType: MULTIPLE_CHILDREN_DISCOUNT_TYPE,
    RelatedPersonList: [],
    Reason: '',
  })
  const [disabledChildrenFormState, setDisabledChildrenFormState] = useState({
    PersonCode: person.PersonCode,
    DiscountType: DISABLED_CHILDREN_DISCOUNT_TYPE,
    RelatedPersonList: [],
    Reason: '',
  })
  const [checkboxState, setCheckboxState] = useState({
    isMultipleChildrenTouched: false,
    isDisabledChildrenTouched: false,
  })

  useQuery(
    ['privileged-children-data', { personCode: person.PersonCode }],
    (_, { personCode }) => fetchPersonsChildren({ PersonCode: personCode }),
    {
      onSuccess: ({ data }) => {
        /**
         * Multiple children family data processing
         * and local state setting
         */
        const multiplteChildrenList = data.filter(
          (children) => children.DiscountType === MULTIPLE_CHILDREN_DISCOUNT_TYPE
        )
        setMultipleChildrenData(multiplteChildrenList)

        const selectedMultipleChildrenPersonCodes = multiplteChildrenList
          .filter((child) => child.LinkStatus)
          .map((child) => ({ PersonCode: child.PersonCode }))

        setMultipleChildrenFormState({
          ...multipleChildrenFormState,
          RelatedPersonList: selectedMultipleChildrenPersonCodes,
        })

        /**
         * Disabled children family data processing
         * and local state setting
         */

        const disabledChildrenList = data.filter(
          (children) => children.DiscountType === DISABLED_CHILDREN_DISCOUNT_TYPE
        )
        setDisabledChildrenData(disabledChildrenList)

        const selectedDisabledChildrenPersonCodes = disabledChildrenList
          .filter((child) => child.LinkStatus)
          .map((child) => ({ PersonCode: child.PersonCode }))

        setDisabledChildrenFormState({
          ...disabledChildrenFormState,
          RelatedPersonList: selectedDisabledChildrenPersonCodes,
        })
      },
      refetchOnWindowFocus: false,
    }
  )

  const [setPersonChildrenMutation] = useMutation(
    (personsChildrenData) => setPersonsChildren(personsChildrenData),
    {
      onSuccess: () => {
        onSuccess()
        showSnackBar()
      },
      onError: (error) =>
        showSnackBar({
          severity: 'error',
          text: `kļūda: ${error.response?.data.error}`,
        }),
    }
  )

  const handleMultipleChildrenCheckbox = (childPersonCode) => {
    setIsMultipleChildrenFormTouched(true)
    setCheckboxState({ ...checkboxState, isMultipleChildrenTouched: true })
    const checkedPersonCodes = multipleChildrenFormState.RelatedPersonList.map(
      (person) => person.PersonCode
    )

    let personCodeList

    if (checkedPersonCodes.includes(childPersonCode)) {
      personCodeList = checkedPersonCodes.filter((personCode) => personCode !== childPersonCode)
    } else {
      personCodeList = [...checkedPersonCodes, childPersonCode]
    }

    const newRelatedPersonCodes = personCodeList.map((personCode) => ({
      PersonCode: personCode,
    }))

    setMultipleChildrenFormState({
      ...multipleChildrenFormState,
      RelatedPersonList: newRelatedPersonCodes,
    })
  }

  const handleDisabledChildrenCheckbox = (childPersonCode) => {
    setIsDisabledChildrenFormTouched(true)
    setCheckboxState({ ...checkboxState, isDisabledChildrenTouched: true })
    const checkedPersonCodes = disabledChildrenFormState.RelatedPersonList.map(
      (person) => person.PersonCode
    )

    let personCodeList

    if (checkedPersonCodes.includes(childPersonCode)) {
      personCodeList = checkedPersonCodes.filter((personCode) => personCode !== childPersonCode)
    } else {
      personCodeList = [...checkedPersonCodes, childPersonCode]
    }

    const newRelatedPersonCodes = personCodeList.map((personCode) => ({
      PersonCode: personCode,
    }))

    setDisabledChildrenFormState({
      ...disabledChildrenFormState,
      RelatedPersonList: newRelatedPersonCodes,
    })
  }

  const isMultipleChildrenFormValid = () => {
    const { Reason } = multipleChildrenFormState
    const _errors = []

    if (!Reason) _errors['Reason'] = defaultErrMsg

    setMultipleChildrenFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const isDisabledChildrenFormValid = () => {
    const { Reason } = disabledChildrenFormState
    const _errors = []

    if (!Reason) _errors['Reason'] = defaultErrMsg

    setDisabledChildrenFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onMultipleChildrenSubmit = () => {
    if (!isMultipleChildrenFormValid()) return

    setPersonChildrenMutation(multipleChildrenFormState).then(() => {
      setMultipleChildrenFormState({ ...multipleChildrenFormState, Reason: '' })
      setIsMultipleChildrenFormTouched(false)
    })
  }

  const onDisabledChildrenSubmit = () => {
    if (!isDisabledChildrenFormValid()) return

    setPersonChildrenMutation(disabledChildrenFormState).then(() => {
      setDisabledChildrenFormState({ ...disabledChildrenFormState, Reason: '' })
      setIsDisabledChildrenFormTouched(false)
    })
  }

  const handleChildrenModal = async (personCode = '') => {
    if (isMultipleChildrenFormTouched || isDisabledChildrenFormTouched) {
      const confirm = await confirmDialog({
        text: 'Nesaglabātas izmaiņas!',
        description:
          'Ievadformā tika veiktas izmaiņas. Vai tiešām vēlaties aizvērt ievadformu, nesaglabājot veiktās izmaiņas?',
      })
      if (confirm.ok) {
        onClose(personCode)
      }
    } else {
      onClose(personCode)
    }
  }

  return (
    <Dialog open onClose={onClose} disableBackdropClick>
      <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
        <CancelIcon
          className={classes.closeIcon}
          color="primary"
          onClick={() => handleChildrenModal()}
        />
      </Box>
      <Box pb={2}>
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="center">
            Labot bērnu piesaisti
          </Box>
        </DialogTitle>
        <DialogContent className={classes.contentBox}>
          <ParentRow person={person} />
          <Divider className={classes.divider} />
          {multipleChildrenData.length > 0 ? (
            <>
              <ChildrenRows
                title="Daudzbērnu ģimene"
                childrenData={multipleChildrenData}
                childrenFormState={multipleChildrenFormState}
                person={person}
                handleChildrenCheckbox={(personCode) => handleMultipleChildrenCheckbox(personCode)}
                onManualChildrenOpen={(personCode) => handleChildrenModal(personCode)}
                isFormDisabled={isDisabledChildrenFormTouched}
              />
              <Box className={classes.justificationBox}>
                <TextField
                  variant="outlined"
                  label="Iemesls"
                  rows={2}
                  rowsMax={6}
                  value={multipleChildrenFormState.Reason}
                  error={Boolean(multipleChildrenFormErrors.Reason)}
                  helperText={multipleChildrenFormErrors.Reason}
                  onChange={(e) => {
                    setIsMultipleChildrenFormTouched(true)
                    setMultipleChildrenFormErrors({ ...multipleChildrenFormErrors, Reason: '' })
                    setMultipleChildrenFormState({
                      ...multipleChildrenFormState,
                      Reason: e.target.value,
                    })
                  }}
                  disabled={!checkboxState.isMultipleChildrenTouched}
                  multiline
                  fullWidth
                />
                <Box className={classes.justificationSaveButtonBox}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.justificationSaveButton}
                    onClick={onMultipleChildrenSubmit}
                    disabled={!checkboxState.isMultipleChildrenTouched}
                  >
                    Saglabāt
                  </Button>
                </Box>
              </Box>
            </>
          ) : null}

          {disabledChildrenData.length > 0 ? (
            <>
              <ChildrenRows
                title="Saistītā persona bērnam ar invaliditāti"
                childrenData={disabledChildrenData}
                childrenFormState={disabledChildrenFormState}
                person={person}
                handleChildrenCheckbox={(personCode) => handleDisabledChildrenCheckbox(personCode)}
                onManualChildrenOpen={(personCode) => handleChildrenModal(personCode)}
                isFormDisabled={isMultipleChildrenFormTouched}
              />
              <Box className={classes.justificationBox}>
                <TextField
                  variant="outlined"
                  label="Iemesls"
                  rows={2}
                  rowsMax={6}
                  value={disabledChildrenFormState.Reason}
                  error={Boolean(disabledChildrenFormErrors.Reason)}
                  helperText={disabledChildrenFormErrors.Reason}
                  onChange={(e) => {
                    setIsDisabledChildrenFormTouched(true)
                    setDisabledChildrenFormErrors({ ...disabledChildrenFormErrors, Reason: '' })
                    setDisabledChildrenFormState({
                      ...disabledChildrenFormState,
                      Reason: e.target.value,
                    })
                  }}
                  disabled={!checkboxState.isDisabledChildrenTouched}
                  multiline
                  fullWidth
                />
                <Box className={classes.justificationSaveButtonBox}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.justificationSaveButton}
                    onClick={onDisabledChildrenSubmit}
                    disabled={!checkboxState.isDisabledChildrenTouched}
                  >
                    Saglabāt
                  </Button>
                </Box>
              </Box>
            </>
          ) : null}
        </DialogContent>
      </Box>
    </Dialog>
  )
}
